import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import defaultImage from './img/logo.png'; // Import your default logo image
import './index.css'
import logo from './assets/gLogo.png'
import Container from '@mui/material/Container';

const LoginForm = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [logoURL, setLogoURL] = useState(null);


  useEffect(() => {

    // Subscribe to Firebase authentication state changes
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setIsLoggedIn(true);

        // Fetch user data from Firebase and update the component's state
        const dbRef = firebase.database().ref(`users/${currentUser.uid}`);
        dbRef.on('value', (snapshot) => {
          const userData = snapshot.val();
          setCompanyData(userData.company);
          setProductsData(userData.products);
          // If the user has a logoURL, update the state
          if (userData.company?.logoURL) {
            setLogoURL(userData.company.logoURL);
          }
        });
      } else {
        setUser(null);
        setIsLoggedIn(false);
        // Reset the component state when the user logs out
        setCompanyData({
          logo: null,
          fullName: "",
          shortName: "",
          taxId: "",
          id: "",
          email: "",
          code: "",
          city: "",
          street: "",
          country: "",
          sameMailing: true,
          mailingCode: "",
          mailingCity: "",
          mailingStreet: "",
          mailingCountry: "",
          bankName: "",
          bankAccountNo: ""
          // ... (other fields)
        });
        setProductsData([{ productName: "", PKWiU: "", worthNet: "", rate: "" }]);
        setLogoURL(null);
      }
    });

    // Unsubscribe from the Firebase listener when the component is unmounted
    return () => unsubscribe();
  }, []);

  const removeProduct = (index) => {
    setProductsData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0]; // Get the selected file from the input
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      // Check if the file is an image (png or jpeg/jpg)
      setCompanyData((prevData) => ({
        ...prevData,
        logo: file,
      }));
    } else {
      // Invalid file format, reset the logo state
      setCompanyData((prevData) => ({
        ...prevData,
        logo: null,
      }));
      alert('Please select a valid PNG or JPEG image for the logo.');
    }
  };

  // Fetch user's photoURL from Firebase Authentication if available
  useEffect(() => {
    if (isLoggedIn && user && user.photoURL) {
      setLogoURL(user.photoURL);
    }
  }, [isLoggedIn, user]);

  const [companyData, setCompanyData] = useState({
    logo: null,
    fullName: "",
    shortName: "",
    taxId: "",
    id: "",
    email: "",
    code: "",
    city: "",
    street: "",
    country: "",
    sameMailing: true,
    mailingCode: "",
    mailingCity: "",
    mailingStreet: "",
    mailingCountry: "",
    bankName: "",
    bankAccountNo: ""
  });

  const [productsData, setProductsData] = useState([
    {
      productName: "",
      PKWiU: "",
      worthNet: "",
      rate: ""
    }
  ]);

  // Add your Firebase SDK configuration here
  const firebaseConfig = {
    apiKey: "AIzaSyAKTurOeCaYXdTLCf_Eiwn6Zl4Gk_aOQB0",
    authDomain: "invoiceserver-3.firebaseapp.com",
    databaseURL: "https://invoiceserver-3-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "invoiceserver-3",
    storageBucket: "invoiceserver-3.appspot.com",
    messagingSenderId: "613935684042",
    appId: "1:613935684042:web:09072b782c24eab859d733",
    measurementId: "G-JWKPSXNSCW"
  };

  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const handleGoogleLogin = async () => {
    try {
      // Initialize Firebase with your Firebase config
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);

      if (result.user) {
        setUser(result.user);
        setIsLoggedIn(true);
      }

    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  const handleCompanyChange = (event) => {
    const { name, value } = event.target;
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleProductChange = (index, event) => {
    const { name, value } = event.target;
    setProductsData((prevData) => {
      const newData = [...prevData];
      newData[index][name] = value;
      return newData;
    });
  };

  const addProduct = () => {
    setProductsData((prevData) => [
      ...prevData,
      {
        productName: "",
        PKWiU: "",
        worthNet: "",
        rate: ""
      },
    ]);
  };

  const handleSaveDetails = async () => {
    if (isLoggedIn && user) {
      const userData = {
        displayName: user.displayName,
        email: user.email,
        photoURL: logoURL, // Set the user's photoURL to the logo download URL
        company: companyData,
        products: productsData,
        // Add other user data you want to store
      };
      if (props.onLoginSuccess) {
        props.onLoginSuccess(userData);
        console.log("Data sent to onLoginSuccess:", userData);
      }
      if (companyData.logo) {
        try {
          // Delete the old image if it exists
          if (user.photoURL) {
            try {
              const oldImageRef = firebase.storage().refFromURL(user.photoURL);
              await oldImageRef.delete();
            } catch (error) {
              console.error('Error deleting old image:', error);
            }
          }

          const storageRef = firebase.storage().ref(`logos/${user.uid}`);
          const snapshot = await storageRef.put(companyData.logo);
          const downloadURL = await snapshot.ref.getDownloadURL();
          userData.company.logoURL = downloadURL; // Add the logo download URL to the user data
          setLogoURL(downloadURL); // Set the logoURL state

          // Update the user's photoURL in Firebase Authentication
          await user.updateProfile({
            photoURL: downloadURL,
          });

          console.log("User profile updated successfully with the logo URL.");

          // Save the user data to the database
          const dbRef = firebase.database().ref(`users/${user.uid}`);
          dbRef.set(userData);
          console.log("User data saved to the database:", userData);
        } catch (error) {
          console.error('Error uploading the logo:', error);
        }
      } else {
        // Save the user data to the database without updating the logoURL
        const dbRef = firebase.database().ref(`users/${user.uid}`);
        dbRef.set(userData);
        console.log("User data saved to the database:", userData);
      }
    }
  };

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      setIsLoggedIn(false);
      setUser(null);
      props.clearCustomerData();
      props.setFormDataCustomer({
        company: {
          logo: "",
          fullName: "",
          shortName: "",
          taxId: "",
          id: "",
          email: "",
          code: "",
          city: "",
          street: "",
          country: "",
          sameMailing: true,
          mailingCode: "",
          mailingCity: "",
          mailingStreet: "",
          mailingCountry: "",
          bankName: "",
          bankAccountNo: ""
          // ... (other fields)
        },
      });
      props.handleLogout();
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <>
          <h2 className="welcome-text">{props.t("Welcome")}, {user && user.displayName}</h2>
          <button className="logout-button" onClick={handleLogout}>Logout</button>
          <button className="save-button" onClick={handleSaveDetails}>Set Details</button>
          <h3 className="company-info-heading">{props.t("Company Information")}</h3>
          <div className="logo-container">
            <label className="logo-label">Logo (PNG/JPEG)</label>
            <input type="file" accept="image/png, image/jpeg" onChange={handleLogoChange} />
            {logoURL ? (
              <img className="company-logo" src={logoURL} alt="Company Logo" style={{ maxWidth: '200px' }} />
            ) : (
              <img className="default-logo" src={defaultImage} alt="Default Logo" style={{ maxWidth: '200px' }} />
            )}
          </div>
          {Object.entries(companyData).map(([key, value]) => (
            <div className='container_L1' >
            <div className='container_L' key={key}>
              <label className='L_lable'>{props.t(key)}</label>
              <input className='L_input' type="text" name={key} value={value} onChange={handleCompanyChange} />
            </div>
            </div>
          ))}
         {/*  <h3>Products</h3>
          {productsData.map((product, index) => (
            <div key={index}>
              <label className='L_lable'>Product Name</label>
              <input className='L_input' type="text" name="productName" value={product.productName} onChange={(e) => handleProductChange(index, e)} />

              <label className='L_lable'>Rate</label>
              <input className='L_input' type="number" name="rate" value={product.rate} onChange={(e) => handleProductChange(index, e)} />

              <label className='L_lable'>Net Worth</label>
              <input className='L_input' type="number" name="worthNet" value={product.worthNet} onChange={(e) => handleProductChange(index, e)} />

              {/* Add more input fields for other product information */}

             {/*} {index > 0 && (
                <button onClick={() => removeProduct(index)}>Remove</button>
              )}
            </div>
          ))}

          <button onClick={addProduct}>Add Product</button>*/}
           <div className='container_L1' >
          <button className='save-button' onClick={handleSaveDetails}>Save Details</button>
          </div>
        </>
      ) : (
        <>

<div className='container Login_scr bg-gradient-to-r from-green-100 to-blue-100 drop-shadow-2xl'>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div>
            <img src={logo} width='100px' alt='logo' />
        </div>
        <div>
            <button className="login-button border_login" onClick={handleGoogleLogin}>Login with Google</button>
        </div>
    </div>
</div>


        </>
      )}
    </div>
  );
};

export default LoginForm;
