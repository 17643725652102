const translations = {
    "Invoice Details " : "Detale faktury",
    "Company Detail" : "Dane firma",
    "Product Information" : "Produkt",
    "Customer Detail" : "Klienta",
    "Print" : "Drukuj",
    "Date Of Issue": "Data wystawienia",
    "Sale Date": "Data sprzedaży",
    "Advance payed": "Advance payed1",
    "Payment Deadline": "Termin płatności",
    "Invoice No.": "Faktura nr",
    "Payment Method" : "Metoda płatności",
    "Cash": "Gotówka",
    "Transfer": "przelew",
    "Compensation": "kompensata",
    "Cash On delivery" : "za pobraniem",
    "Credit / Debit Card" : "karta płatnicza",
    "All Paid" : "Zapłacono",
    "Yes": "Tak",
    "No":"Nie",
    "Invoice Nr":"Faktura numer ",
    "Invoice Date": "Data rozliczenia faktury",
    "Product Detail" : "Dane Produkt ",
    "Add More product": "Nowy wiersz",
    "Product": "Produkt",
    "PKWiU" : "PKWiU",
    "Unit": "Jedn.",
    "Quantity": "Ilość ",
    "Discount%": "Rabat %",
    "Rate": "Stawka",
    "Worth Net" : "Wart. netto",
    "Worth Gross":"Wart. brutto",
    "GTU":"GTU",
    "Action" : "Działanie",
    "name": "Nazwa pełna",
    "pkwiu": "pkwiu",
    "unit": " Jedn ",
    "qty": " Ilość ",
    "discount": " Rabat ",
    "rate": " Stawka ",
    "worthNet": " Wart. netto ",
    "worthGross": " Wart. brutto",
    "gtu": "GTU",
    "remove": " Usunąć ",
    "description": "Opis",
    "nip": "NIP",
    "Full Name": " Nazwa pełna ",
    "Short Name": " Nazwa skrócona ",
    "email": " Adres e-mail ",
    "taxId":" NIP Identyfikator ",
    "id": " Identyfikator ",
    "Code": "Kod",
    "City": "Miasto",
    "Street, House flat": " Ulica, dom / lokal ",
    "Country": "Kraj",
    "Bank Acount Name" : " Nazwę banku ",
    "Bank Account Number": " Nr Konto bankowe ",
    "Enter logo url": " Wpisz adres URL logo ",
    "Generate pdf" : " Drukuj plik PDF",
    "Net Price" : "Net Price",
    "Search NIP" : "Szukaj NIP",
    "Signature of the authorized person" : "Imię i nazwisko osoby uprawnionej",
    "invoice from" : "zrobić prezentację faktur",
    "invoice to" : "do odbioru faktury",
    "Total" : "Razem",
    "To pay" : "Do zapłaty",
    "Paid" : "Zapłacono",
    "VAT Rate" : "Stawka VAT",
    "Net Value" : "Wartość netto",
    "VAT Amount" : "Kwota VAT",
    "Gross Value" : "Wartość brutto",
    "Sr" : "Lp",
    "Seller": "Sprzedawca",
    "Login":"Zaloguj sie",
    "Invoice Details":"Dane do faktury",
    "Welcome":"Witam",
    "Company Information":"Dane firmy",
    "logoURL": "logo",
    "fullName": " Nazwa pełna",
    "shortName": "Nazwa skrócona",
    "code": "Kod poczta",
    "city": "Miasto",
    "street": "Ulica",
    "country": "Kraj",
    "sameMailing": "sam adres",
    "mailingCode": "Kod pocztowy",
    "mailingCity": "Poczta Miasto",
    "mailingStreet": "ulica pocztowa",
    "mailingCountry": "kraj poczty",
    "bankName": "Nazwa banku",
    "bankAccountNo": "Konta Numer",
    "or Login":"lub zaloguj się",
    "Please insert your NIP number to get information automatically":" Prosimy o wpisanie numeru NIP, aby uzyskać informacje automatycznie",
    "To view this page, please open it in desktop view.":"Aby wyświetlić tę stronę, otwórz ją w widoku pulpitu.",
    "Generating PDF...":"GENERUJ PDF...",
    "Download PDF":'ŚCIĄGNIJ PDF',
    "free": "Darmowe",
    "invoice": "Faktura",
    "download without login or ads": "Pobierz bez logowania lub reklam",
    "Free Invoice": "Darmowa faktura",
    "Simplify  Billing": "Ułatwienie w fakturowaniu",
    "Welcome to our Free Invoice System, where simplicity meets efficiency! Streamline your billing process effortlessly with our user-friendly and feature-packed invoicing solution. Say goodbye to manual invoicing headaches and embrace a smarter way to manage your finances.": "Witamy w naszym systemie Darmowej Faktury, gdzie prostota spotyka się z efektywnością! Ułatw swoje procesy fakturowania bez wysiłku dzięki naszemu przyjaznemu użytkownikowi i bogatemu w funkcje rozwiązaniu do fakturowania. Pozbądź się bólu głowy związanego z ręcznym fakturowaniem i przywitaj się z inteligentnym sposobem zarządzania finansami.",
    "User Active": "Aktywni użytkownicy",
    "Trusted by small business": "Zaufane przez małe firmy",
    "Downloads": "Pobrania",
    "You do the business,": "Ty prowadź swoją firmę,",
    "we'll handle the Invoice.": "a my zajmiemy się Fakturą.",
    "With the right invoice tool,you can improve your financial life by easy generating invoice,easily generate invoice and save time.But without login generate samll invoices.": "Dzięki odpowiedniemu narzędziu do fakturowania, możesz poprawić swoje życie finansowe, łatwo generować faktury, zaoszczędzić czas. Ale bez logowania generuj małe faktury.",
    "Totally Free": "Całkowicie darmowe",
    "The best invoice tool offer some small business, restaurents and shops": "Najlepsze narzędzie do fakturowania oferuje pewnym małym firmom, restauracjom i sklepom",
    "100% Secured": "100% zabezpieczone",
    "We take proactive steps make sure your information and invoices are secure.": "Podejmujemy proaktywne kroki, aby upewnić się, że Twoje informacje i faktury są bezpieczne.",
    "Transfer Link": "Link do transferu",
    "A balance transfer via free invoice too can save your time a via direct transfer link.": "Transfer środków za pomocą darmowego narzędzia do fakturowania może zaoszczędzić czas za pomocą bezpośredniego linku do transferu.",
    "Easily control your ": "Łatwo kontroluj swoje ",
    "billing & invoicing.": "fakturowanie i fakturowanie.",
    "You can issue any invoices in 2 languages, in easy template with and with your own LOGO. Invoicing tailored to the specificity of each company.": "Możesz wystawiać dowolne faktury w 2 językach, w łatwym szablonie, z własnym LOGO. Fakturowanie dostosowane do specyfiki każdej firmy.",
    "Find a better Invoice tool": "Znajdź lepsze narzędzie do fakturowania",
    "in a few easy steps.": "w kilku łatwych krokach.",
    "You can quickly issue an invoice, easily calculate taxes and settle accounts with Accountant. Simple, convenient, without unnecessary complications.": "Możesz szybko wystawić fakturę, łatwo obliczyć podatki i rozliczyć się z księgowym. Prosto, wygodnie, bez niepotrzebnych komplikacji.",
    "What people are": "Co ludzie mówią o nas",
    "saying about us": "o nas",
    "Everything you need easy invoicing tool and grow your business anywhere on the planet.": "Wszystko, czego potrzebujesz w łatwym narzędziu do fakturowania, aby rozwijać swoją firmę w dowolnym miejscu na ziemi.",
    "Free invoice is only a tool for small business. It will take you wherever you wish, but it will not replace you as the driver.": "Darmowa faktura to tylko narzędzie dla małych firm. Zabierze Cię wszędzie tam, gdzie zechcesz, ale nie zastąpi Cię jako kierowcy.",
    "Easy invoice makes your life easier. If you're lucky to have it, you're lucky.": "Prosta faktura ułatwia życie. Jeśli masz szczęście ją mieć, to masz szczęście.",
    "It is usually people in the money business, finance, and international trade that are really rich to get free invoice.": "Zazwyczaj ludzie w biznesie finansowym, finansach i handlu międzynarodowym są naprawdę bogaci, aby dostać darmową fakturę.",
    "Everything you need is an easy invoicing tool to grow your business anywhere on the planet.": "Wszystko, czego potrzebujesz, to łatwe narzędzie do fakturowania, aby rozwijać swoją firmę w dowolnym miejscu na ziemi.",
    "Let's try our service now!": "Spróbuj naszej usługi teraz!",
    "Everything you need is an easy invoicing tool to grow your business anywhere on the planet.": "Wszystko, czego potrzebujesz, to łatwe narzędzie do fakturowania, aby rozwijać swoją firmę w dowolnym miejscu na ziemi.",
    "Useful Links":"Przydatne linki",
    "Community":"Wspólnota",
    "Partner":"Partner",
    "Home":"Strona główna",
    "Features":"Funkcje",
    "Products":"Produkty",
    "Clients":"Klienci",
    "GDPR compliant program":"Program zgodności z RODO",
    "Information about cookies":"Informacje o plikach cookies",
    "Terms & Services":"Warunki i usługi",
    "Privacy policy":"Polityka prywatności",
    "Become a Partner":"Stać się partnerem",
    "A new way to make the Invoice easy, reliable and fast.":"Nowy sposób na łatwe, niezawodne i szybkie wystawianie faktur.",
    "© 2023 Scopewit Sp.zo.o. All Rights Reserved.":"© 2023 Scopewit Sp.zo.o. Wszelkie prawa zastrzeżone",
    "Get Started":"Zaczynaj",
};

export const translate = (key, lang) => {
    if(lang === "pl") {
        return translations[key]
    } else {
        return key;
    }
};