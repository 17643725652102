import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { colors } from '@mui/material';
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
  return (
    <div style={{background:'black'}}>
    <div className='container privacy-policy-container' style={{color:'white'}} >
    <Navbar/>
    <h1>English</h1>
      <h1>Privacy Policy</h1>

      <p>Treating the security of personal data processed by the #.pl website as a priority, we make sure that all our activities are in accordance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation), hereinafter also referred to as GDPR. Fulfilling information obligations, in order to maintain transparency and understandability of our principles of protection of your personal data, we present this privacy policy to you.</p>
        <h2> General information</h2>
        <p>The administrator of personal data processed by the #.pl website, which consists primarily of:

        https://#.pl/ and its subpages is Web Scopewit Sp. z o. o., based in Wrocław, ul. ruska 47 Wrocław; entered into the Register of Entrepreneurs of the National Court Register kept by the District Court for Wrocław-Fabryczna in Wrocław, 6th Commercial Division of the National Court Register, under KRS number - 0000770018; NIP: 8982248420, REGON: 382515870.</p>
      <p>
        Welcome to our website. This Privacy Policy explains how we collect,
        use, and protect your personal information when you use our services.
      </p>

      <h2>Information We Collect</h2>

      <p>
        <strong>Website Usage:</strong> We do not collect any personal data
        from you while browsing our website. We only collect anonymous usage
        statistics to improve the user experience.
      </p>

      <p>
        <strong>Login Information:</strong> If you choose to log in to our
        website, we collect and store your login credentials in an encrypted
        form. We use Firebase for secure storage and Google for authentication.
      </p>

      <h2>How We Use Your Information</h2>

      <p>
        We use the information collected to provide and improve our services,
        personalize your experience, and ensure the security of your account.
      </p>

      <h2>Data Security</h2>

      <p>
        Your login information is stored securely in an encrypted form using
        industry-standard measures. We take all necessary precautions to
        protect your data; however, no method of transmission over the internet
        or electronic storage is entirely secure.
      </p>

      <h2>Third-Party Services</h2>

      <p>
        We may use third-party services to enhance our website's functionality.
        These services have their privacy policies, and we encourage you to
        review them.
      </p>

      <h2>Changes to This Privacy Policy</h2>

      <p>
        We may update our Privacy Policy from time to time. Any changes will be
        posted on this page, and the date of the latest revision will be
        indicated.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions or concerns about our Privacy Policy, please
        contact us at <a href="mailto:info@example.com">info@example.com</a>.
      </p>
      <h1>Polish</h1>
      <h1>Polityka Prywatności</h1>
      <h1>Privacy Policy</h1>

      <p>Traktując jako priorytet bezpieczeństwo danych osobowych przetwarzanych przez serwis #.pl dbamy aby wszystkie nasze działania pozostawały w zgodzie z  Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), dalej także jako RODO. Wypełniając obowiązki informacyjne, dla zachowania transparentności i zrozumiałości stosowanych przez nas zasad ochrony Państwa danych osobowych, przedstawiamy Państwu niniejszą politykę prywatności.</p>
        <h2> Informacje ogólne</h2>
        <p>Administratorem danych osobowych przetwarzanych przez serwis #.pl na który składa się przede wszystkim:

https://#.pl/ i jej podstrony

jest Scopewit Sp. z o.o, z siedzibą we Wrocławiu, ul. Bierutowska 57-59, 51-317 Wrocław; wpisaną do Rejestru Przedsiębiorców Krajowego rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Wrocławia-Fabrycznej we Wrocławiu, VI Wydział Gospodarczy Krajowego Rejestru Sądowego, pod nr KRS 0000770018; NIP: 8982248420, REGON: 382515870.</p>
      <p>
        Welcome to our website. This Privacy Policy explains how we collect,
        use, and protect your personal information when you use our services.
      </p>


  <p>
    Witamy na naszej stronie internetowej. Niniejsza Polityka Prywatności
    wyjaśnia, w jaki sposób zbieramy, używamy i chronimy Twoje dane osobowe podczas
    korzystania z naszych usług.
  </p>

  <h2>Informacje, Które Zbieramy</h2>

  <p>
    <strong>Korzystanie z Strony:</strong> Nie zbieramy żadnych danych osobowych
    od Ciebie podczas przeglądania naszej strony. Zbieramy jedynie anonimowe
    dane statystyczne w celu poprawy doświadczenia użytkownika.
  </p>

  <p>
    <strong>Informacje o Logowaniu:</strong> Jeśli zdecydujesz się zalogować na
    naszej stronie, zbieramy i przechowujemy Twoje dane logowania w formie
    zaszyfrowanej. Wykorzystujemy Firebase do bezpiecznego przechowywania danych
    i Google do uwierzytelniania.
  </p>

  <h2>W jaki sposób Używamy Twoich Informacji</h2>

  <p>
    Zebrane informacje używamy do świadczenia i ulepszania naszych usług,
    personalizacji Twojego doświadczenia i zapewnienia bezpieczeństwa Twojego
    konta.
  </p>

  <h2>Bezpieczeństwo Danych</h2>

  <p>
    Twoje informacje logowania są przechowywane bezpiecznie w formie
    zaszyfrowanej, zgodnie z branżowymi standardami. Podjęliśmy wszystkie
    niezbędne środki ostrożności w celu ochrony Twoich danych; jednak żaden
    sposób przesyłania danych przez internet ani przechowywania elektronicznego
    nie jest w pełni bezpieczny.
  </p>

  <h2>Usługi Trzecie</h2>

  <p>
    Możemy korzystać z usług firm trzecich w celu ulepszenia funkcjonalności
    naszej strony internetowej. Te usługi posiadają własne polityki prywatności,
    a my zachęcamy Cię do ich przeczytania.
  </p>

  <h2>Zmiany w Polityce Prywatności</h2>

  <p>
    Możemy aktualizować naszą Politykę Prywatności od czasu do czasu. Wszelkie
    zmiany zostaną opublikowane na tej stronie, a data ostatniej rewizji będzie
    podana.
  </p>

  <h2>Kontakt</h2>

  <p>
    Jeśli masz jakiekolwiek pytania lub obawy dotyczące naszej Polityki
    Prywatności, skontaktuj się z nami pod adresem{' '}
    <a href="mailto:info@example.com">info@example.com</a>.
  </p>
    </div>
    <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
