import React, { useState } from 'react'
import { close, logo, menu } from '../assets'
import slogo from '../assets/S_logo.png'
import { navLinks } from '../constants'
import '../index.css'
import Button from './Button'
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { translate } from '../lang';

const Navbar = ({language}) => {
  const [toggle, setToggle] = useState(false)

  return (
    <nav className='w-full flex py-6 justify-between items-center navbar'>
      <img src={slogo} alt='Free invoice' className='w-[124px] h-[32px]'/>
      <ul className='list-none sm:flex mdhidden justify-end items-center flex-1'>
        {navLinks.map((nav, i) => (
          <li
            key={nav.id}
            className={`font-poppins font-normal cursor-pointer text-[16px] ${i === navLinks.length - 1 ? 'mr-0' : 'mr-10'} text-white mr-10`}
          >
            <a href={`#${nav.id}`}>
            {translate(nav.title, language)}
            </a>
          </li>
        ))}
      <Button language={language} styles='mt-10'/>
      </ul>
      <div className='sm:hidden flex flex-1 justify-end items-center'>
        <img
          src={toggle ? close : menu}
          alt='menu'
          className='w-[28px] h-[28px] object-contain'
          onClick={() => setToggle((previous) => !previous)}
        />
        <div className={`${toggle ? 'flex' : 'hidden'} p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}>
          <ul className='list-none flex flex-col justify-end items-center flex-1'>
          {navLinks.map((nav, i) => (
              <li
                key={nav.id}
                className={`font-poppins font-normal cursor-pointer text-[16px] ${i === navLinks.length - 1 ? 'mr-0' : 'mb-4'} text-white mr-10`}
              >
                <a href={`#${nav.id}`}>
                  {translate(nav.title, language)}
                </a>
              </li>
            ))}
            <Button styles='mt-10'/>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
