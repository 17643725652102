import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import './PrivacyPolicy.css'

const Tc = () => {
  return (
    <div style={{background:'black'}}>
        <div className='container regulations-container' style={{ color: 'white' }}>
        <Navbar/>
    <h1>Regulamin świadczenia usług</h1>

    <h2>§ 1. DEFINICJE</h2>

    <p>
      W niniejszym regulaminie użyte terminy oznaczają:
    </p>

    <ul>
      <li><strong>Usługodawca</strong> - Scopewit Sp. z o.o., świadczący usługi za pośrednictwem systemu Free Invoice,</li>
      <li><strong>Usługobiorca</strong> - osoba korzystająca z usług świadczonych przez Usługodawcę poprzez system Free Invoice,</li>
      <li><strong>System</strong> - platforma internetowa Free Invoice służąca do wystawiania faktur, zarządzania danymi itp.,</li>
      <li><strong>Regulamin</strong> - niniejszy dokument, określający warunki korzystania z usług świadczonych przez Usługodawcę za pośrednictwem Systemu.</li>
    </ul>

    <h2>§ 2. POSTANOWIENIA OGÓLNE</h2>

    <p>
      Niniejszy regulamin określa zasady korzystania z usług świadczonych przez Usługodawcę za pośrednictwem Systemu Free Invoice.
    </p>

    <h2>§ 3. RODZAJE I ZAKRES USŁUG</h2>

    <p>
      Usługodawca świadczy usługi związane z korzystaniem z systemu Free Invoice, umożliwiające wystawianie faktur, zarządzanie danymi itp.
    </p>

    <h2>§ 4. WARUNKI TECHNICZNE KORZYSTANIA ZE STRONY I REJESTRACJI</h2>

    <p>
      Warunki techniczne korzystania ze strony oraz zasady rejestracji określone są w regulaminie.
    </p>

    <h2>§ 5. OKRES TESTOWY LUB DARMOWE USŁUGI</h2>

    <p>
      System Free Invoice może oferować okres testowy lub darmowe usługi. Warunki korzystania z tych usług są określone w regulaminie.
    </p>

    <h2>§ 6. ZAWIERANIE UMOWY O ŚWIADCZENIE USŁUG</h2>

    <p>
      Umowa o świadczenie usług zostaje zawarta na określony czas, zgodny z okresem subskrypcji. Warunki zawarcia umowy są opisane w regulaminie.
    </p>

    <h2>§ 7. PŁATNOŚCI</h2>

    <p>
      (To be developed in the future.)
    </p>

    <h2>§ 8. PRZEDŁUŻANIE OKRESU SUBSKRYPCJI</h2>

    <p>
      (To be developed in the future.)
    </p>

    <h2>§ 9. USUWANIE KONTA</h2>

    <p>
      Usługobiorca ma prawo usunąć swoje konto w Systemie. Warunki usunięcia konta są opisane w regulaminie.
    </p>

    <h2>§ 10. ZASADY UMIESZCZANIA KOMENTARZY NA STRONIE</h2>

    <p>
      (To be developed in the future.)
    </p>

    <h2>§ 11. ODPOWIEDZIALNOŚĆ</h2>

    <p>
      (To be developed in the future.)
    </p>

    <h2>§ 12. DANE UŻYTKOWNIKA I ICH OCHRONA</h2>

    <p>
      (To be developed in the future.)
    </p>

    <h2>§ 13. PRZETWARZANIE DANYCH OSOBOWYCH</h2>

    <p>
      (To be developed in the future.)
    </p>

    <h2>§ 14. PROCEDURA SKŁADANIA SKARG</h2>

    <p>
      Skargi dotyczące usług mogą być składane przez Usługobiorcę na zasadach opisanych w regulaminie.
    </p>

    <p>
      Usługobiorca może złożyć skargę w ciągu 14 dni od daty zdarzenia, które ją spowodowało.
    </p>

    <p>
      Skarga musi zawierać dane Usługobiorcy umożliwiające kontakt z nim, w tym dane umożliwiające identyfikację:
    </p>

    <ul>
      <li>osoby składającej skargę jako Usługobiorcy,</li>
      <li>konkretną Usługę, do której odnosi się skarga,</li>
      <li>zarzuty Usługobiorcy dotyczące wskazanej Usługi,</li>
      <li>okoliczności uzasadniające skargę,</li>
      <li>ewentualne żądanie Usługobiorcy związane ze złożoną skargą.</li>
    </ul>

    <p>
      Skargi będą rozpatrywane w ciągu 14 dni od daty otrzymania listu poleconego lub e-maila od Usługodawcy.
    </p>

    <p>
      Podczas rozpatrywania skargi, Usługodawca będzie stosować postanowienia regulaminu.
    </p>

    <p>
      Usługobiorca zostanie poinformowany o decyzji Usługodawcy na piśmie pod adres podany w liście poleconym zawierającym skargę lub drogą e-mailową.
    </p>

    <h2>§ 15. OKRES TRWANIA UMOWY I JEJ ROZWIĄZANIE</h2>

    <p>
      Umowa o świadczenie usługi jest zawierana na czas określony, równy okresowi subskrypcji. Przedłużenie okresu subskrypcji skutkuje przedłużeniem umowy bez konieczności składania odrębnych oświadczeń woli. Umowa o świadczenie usługi zostanie rozwiązana w wyniku:
    </p>

    <ul>
      <li>nieprzedłużenia okresu subskrypcji danej Usługi zgodnie z postanowieniami regulaminu,</li>
      <li>śmierci Usługobiorcy,</li>
      <li>zakończenia istnienia prawnego Usługobiorcy,</li>
      <li>usunięcia konta w Systemie przez Usługobiorcę.</li>
    </ul>

    <p>
      Usługodawca będzie uprawniony do rozwiązania umowy o świadczenie danej Usługi, jeżeli:
    </p>

    <ul>
      <li>Usługobiorca istotnie naruszy postanowienia Umowy lub regulaminu,</li>
      <li>Usługobiorca będzie używał Usługi niezgodnie z jej parametrami określonymi na Stronie, celem Usługi lub zasadami współżycia społecznego,</li>
      <li>Usługobiorca będzie działał na szkodę Usługodawcy, innych klientów Usługodawcy lub użytkowników Internetu,</li>
      <li>Usługobiorca będzie prowadził działania nielegalne lub mające na celu kompromitację bezpieczeństwa danych znajdujących się na Stronie.</li>
    </ul>

    <p>
      Przed rozwiązaniem umowy, Usługodawca wezwie Usługobiorcę do zaprzestania naruszeń, ustawiając mu termin nie krótszy niż 14 dni na zaprzestanie tych naruszeń. Wezwanie to może być złożone na piśmie lub drogą e-mailową.
    </p>

    <p>
      Rozwiązanie Umowy może nastąpić na piśmie lub drogą e-mailową.
    </p>

    <h2>§ 16. POSTANOWIENIA KOŃCOWE</h2>

    <p>
      (To be developed in the future.)
    </p>
  </div>;
  <Footer/>
  </div>
  )
}

export default Tc