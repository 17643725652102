import React, { useEffect, useState } from "react";
import "./index.css";
import logo from "../../assets/unnamed.png";
import Button from '@mui/material/Button';
//import Pdf from 'react-to-pdf';
import { PDFDownloadLink  } from '@react-pdf/renderer';
import { PDFViewer, Page, Text, View, Document, StyleSheet, Image, BlobProvider, Font } from '@react-pdf/renderer';
import Customer from "../Customer";
import NumberToWordsComponent from "../wordToNumber";
import { textAlign } from "@mui/system";
import { BrowserView, MobileView,isMobile } from 'react-device-detect';

// Register a font that supports Polish characters
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});

const Index = (props) => {
  const [numberToWordsComponent, setNumberToWordsComponent] = useState(null);
  const ref= React.createRef();
  const [options, setOptions] = useState(
    {
      orientation: 'portrait',
      unit: "pt",
      type: [0, 0],
    }
  )
  useEffect(() => {
    setOptions({
      orientation: 'portrait',
      unit: "pt",
      type : [document.getElementById("print").offsetWidth, document.getElementById("print").offsetHeight]
    })
  }, []);
  const product = props.product || {};
  const company = props.company || {};
  const customer = props.customer || {};

  const cLogo = customer.logo;


  const totalPlaceC = product?.products?.reduce((acc, itm) => {
    const existingItem = acc.find((item) => item.rate === itm.rate);
    if (existingItem) {
      existingItem.placeA += parseFloat(itm.worthNet);
      existingItem.placeB += parseFloat(itm.worthGross - itm.worthNet);
      existingItem.placeC += parseFloat(itm.worthGross);
    } else {
      acc.push({
        rate: itm.rate,
        placeA: parseFloat(itm.worthNet),
        placeB: parseFloat(itm.worthGross - itm.worthNet),
        placeC: parseFloat(itm.worthGross),
      });
    }
    return acc;
  }, []);

  // Calculate the totalC value
  const totalC = totalPlaceC.reduce((total, item) => total + item.placeC, 0).toFixed(2);
  <NumberToWordsComponent totalC={totalC} />

  useEffect(() => {
    // Update NumberToWordsComponent whenever totalC changes
    setNumberToWordsComponent(<NumberToWordsComponent totalC={totalC} />);
  }, [totalC]);

  // Log the value whenever numberToWordsComponent changes
  useEffect(() => {
    //console.log("here are the value...", numberToWordsComponent);
  }, [numberToWordsComponent]);

  const wordsInEnglish = <NumberToWordsComponent totalC={totalC} language1="en" />;
  const wordsInPolish = <NumberToWordsComponent totalC={totalC} language1="pl" />;


  return (<>
  <div className="mobile-view">
    <div style={{ textAlign: 'center', padding: '20px' }}>
         <p>{props.t("To view this page, please open it in desktop view.")}</p>
     </div>
     <PDFDownloadLink  document={<MyDocument {...props} />} fileName="receipt.pdf">
             {({ loading }) => (<Button
           style={{
             margin: '10px',
             padding: '10px',
             width: '250px',
             height: '40px',
             fontSize:'18px',
             backgroundColor: loading ? '#ddd' : '#fff', // Adjust background color based on loading state
             color: loading ? '#777' : '#000', // Adjust text color based on loading state
           }}
           variant="outlined"
         >
           {loading ?  props.t("Generating PDF...") : props.t("Download PDF")}
         </Button>)}
           </PDFDownloadLink>
     </div>
  <div className="container desktop-view" style={{ padding:'10px'}}>
    <div className="container" style={{ textAlign:'center'}}>
   {/* <Pdf targetRef={ref} options={options} filename="reciept.pdf"  scale={1}>
        {({toPdf, targetRef}) => (
            <button className="Btn_download" onClick={toPdf} ref={targetRef}>{props.t("Generate pdf")}</button>
        )}
        </Pdf>*/}

          <PDFDownloadLink  document={<MyDocument {...props} />} fileName="receipt.pdf">
            {({ loading }) => (<Button
          style={{
            margin: '10px',
            padding: '10px',
            width: '250px',
            height: '40px',
            fontSize:'18px',
            backgroundColor: loading ? '#ddd' : '#fff', // Adjust background color based on loading state
            color: loading ? '#777' : '#000', // Adjust text color based on loading state
          }}
          variant="outlined"
        >
          {loading ? 'Generating PDF...' : 'Download PDF'}
        </Button>)}
          </PDFDownloadLink>
    </div>
    <div >
    <div className=" container outer-container" style={{ alignItems:'center', justifyContent:'center', boxShadow:'0 0 10px rgba(0,0,0,0.5)'}} id="print" ref={ref}>
      <div className="division-container">
        <img alt="company logo" width="100px" src={customer.logo ? customer.logo : logo}  className="company-logo" />
        <div className="table-outer-container">
          <div className="table-container">
            <div className="table-col-type-one bold" >
              <p>Faktura nr / Invoice No.: {product.series.toUpperCase()}</p>
            </div>
            <div className="table-col-type-two">
            <p style={{ fontSize:'10px'}}>
                <span style={{color:'orange'}}>
                Data wystawienia <br/> Invoice Date
                </span> :
                <span className="table-col-sub" >{product.dateOfIssue}</span>
              </p>
              <p style={{ fontSize:'10px'}}>
                <span style={{color:'orange'}}>
                Data sprzedaży <br/> Sale Date
                </span> :
                <span className="table-col-sub" > {product.saleDate} </span>
              </p>
            </div>
            <div className="table-col-type-three">
            <p style={{ fontSize:'10px'}}>
              <span style={{color:'orange'}}>
              Termin płatności <br/> Payment Due By:
              </span> :
              <span className="table-col-sub" >{product.paymentDeadline}</span>
            </p>
            <p style={{ fontSize:'10px'}}>
              <span style={{color:'orange'}}>
              Metoda płatności <br/> Payment Method:
              </span> :
              <span className="table-col-sub" >{product.paymentMethod}</span>
            </p>
            </div>
          </div>
        </div>
      </div>

      <div className="division-container-one">
        <div className="text-container">
        <div className="bold">Sprzedawca <span className="italic">(Seller)</span></div>
          <p className="no-margin-padding">{customer.fullName}</p>
          <p className="no-margin-padding">{customer.street}</p>
          <p className="no-margin-padding">{customer.code} {customer.city}</p>
          <p className="no-margin-padding">NIP: {customer.id}</p>
          <p className="no-margin-padding">{customer.bankName}</p>
          <p className="no-margin-padding">{customer.bankAccountNo}</p>
        </div>
        <div className="text-container">
        <div className="bold">Nabywca <span className="italic">(Bill to)</span></div>
          <p className="no-margin-padding">{company.shortName}</p>
          <p className="no-margin-padding">{company.fullName}</p>
          <p className="no-margin-padding">{company.street}</p>
          <p className="no-margin-padding">{company.code} {company.city}</p>
          <p className="no-margin-padding">NIP: { company.id}</p>
        </div>
      </div>

      <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '24px' }} className="empty-div">{product.series.toUpperCase()}</div>


      <div className="table-container-one" >
        <table>
          <tr>
            <th>{props.t("Sr")}</th>
            <th>{props.t("Product")}</th>
            <th>{props.t("PKWiU")}</th>
            <th>{props.t("Unit")}</th>
            <th>{props.t("Quantity")}</th>
            <th>{props.t("Worth Net")}</th>
            <th>{props.t("Discount%")}</th>
            <th>{props.t("Rate")}</th>
            <th>{props.t("Worth Net")}</th>
            <th>{props.t("Worth Gross")}</th>
          </tr>
          {product?.products?.map((itm,index) => (<tr>
            <td>{index + 1}</td>
            <td className="table-left-text">{itm.name}</td>
            <td>{itm.pkwiu}</td>
            <td>{itm.unit}</td>
            <td>{itm.qty}</td>
            <td>{itm.netPrice}</td>
            <td>{itm.discount}%</td>
            <td>{itm.rate}%</td>
            <td>{itm.worthNet}</td>
            <td>{itm.worthGross}</td>
          </tr>))}
        </table>
      </div>

      <div className="division-container">
       {/*  <div className="table-container-one">
          <table>
            <tr>
              <th>Stawka VAT</th>
              <th>Wartość netto</th>
              <th>Kwota VAT</th>
              <th>Wartość brutto</th>
            </tr>
            {product?.products?.map((itm,index) => ( <tr>
              <td className="text-center">{itm.rate}%</td>
              <td>76,85</td>
              <td>6,15</td>
              <td>83,00</td>
            </tr>))}
            <tr>
              <td className="text-center">Razem</td>
              <td>76,85</td>
              <td>6,15</td>
              <td>83,00</td>
            </tr>
          </table>
        </div>*/}
        <div className="table-container-one">
  <table>
    <thead>
      <tr>
        <th>{props.t("VAT Rate")}</th>
        <th>{props.t("Net Value")}</th>
        <th>{props.t("VAT Amount")}</th>
        <th>{props.t("Gross Value")}</th>
      </tr>
    </thead>
    <tbody>
    {product?.products?.reduce((acc, itm) => {


        const existingItem = acc.find((item) => item.rate === itm.rate);
        if (existingItem) {
          existingItem.placeA += parseFloat(itm.worthNet);
          existingItem.placeB += parseFloat(itm.worthGross - itm.worthNet);
          existingItem.placeC += parseFloat(itm.worthGross);
        } else {
          acc.push({
            rate: itm.rate,
            placeA: parseFloat(itm.worthNet),
            placeB: parseFloat(itm.worthGross - itm.worthNet),
            placeC: parseFloat(itm.worthGross),
          });
        }
        return acc;
      }, []).map((item, index, arr) => (
        <React.Fragment key={index}>
          <tr>
            <td className="text-center">{item.rate}%</td>
            <td>{item.placeA.toFixed(2)} Zł</td>
            <td>{item.placeB.toFixed(2)} Zł</td>
            <td>{item.placeC.toFixed(2)} Zł</td>
          </tr>
          {index === arr.length - 1 && (
            <tr>
              <td className="text-center">{props.t("Total")}</td>
              <td>
                {arr.reduce((total, item) => total + item.placeA, 0).toFixed(2)} Zł
              </td>
              <td>
                {arr.reduce((total, item) => total + item.placeB, 0).toFixed(2)} Zł
              </td>
              <td>
                {arr.reduce((total, item) => total + item.placeC, 0).toFixed(2)} Zł
              </td>
            </tr>
          )}
        </React.Fragment>
      ))}
    </tbody>
  </table>
</div>


        <div className="table-container-one">
          <table className="table-no-outline">
           <tr>
              <td>{props.t("Paid")}</td>
              <td>{product.paid === 'Yes' ? `${totalC} Zł` : '0,00 Zł'}</td>
            </tr>
            <tr>
              <td>{props.t("To pay")}</td>
              <td>{product.paid === 'No' ? (
      product.advance !== '0' ? `${totalC - product.advance} Zł` : `${totalC} Zł`
    ) : (
      '0,00 Zł'
    )}</td>
            </tr>
            <tr>
              <td>{props.t("Total")}</td>
              <td>{totalC} Zł</td>
            </tr>
          </table>
        </div>
      </div>

      <div className="text-container-two">
        <hr></hr>
        {/* <p><NumberToWordsComponent totalC={totalC} /></p> */}
        <p>Words in English: {wordsInEnglish}</p>
        <p>Words in Polish: {wordsInPolish}</p>
      </div>
      <div className="space-container" />

      <div className="division-container">
        <div className="text-container-3">
          <p className="bold">{customer.fullName.toUpperCase()}</p>

          <p>{props.t("Signature of the authorized person")}</p>
          <p>{props.t("invoice from")}</p>
        </div>
        <div className="text-container-3">
        <p className="bold">{company.fullName.toUpperCase()}</p>
          <p>{props.t("Signature of the authorized person")}</p>
          <p>{props.t("invoice to")}</p>
        </div>
      </div>
      <hr/>
      <p className="text-center">{customer.fullName} | {customer.email}</p>
    </div>
    </div>
    </div>
    <div className="container" style={{ textAlign:'center'}}>
   {/*} <Pdf targetRef={ref} options={options} filename="reciept.pdf"  scale={1}>
        {({toPdf, targetRef}) => (
            <button className="Btn_download" onClick={toPdf} ref={targetRef}>{props.t("Generate pdf")}</button>
        )}
        </Pdf> */}
    </div>
    </>
  );
};

const MyDocument = (props) => {
  const { product, company, customer } = props;
  const totalC = product?.products?.reduce((total, itm) => total + parseFloat(itm.worthGross), 0).toFixed(2);
  const wordsInEnglish = <NumberToWordsComponent totalC={totalC} language1="en" />;
  const wordsInPolish = <NumberToWordsComponent totalC={totalC} language1="pl" />;

  return (
    <Document>
  <Page size="A4" style={{ marginBottom: '0mm', paddingBottom: '0mm' }} >
    <View style={[styles.container, styles.outerContainer]} id="print">
    <View style={[styles.divisionContainer, styles.divisionContainerOne]}>
      <View style={styles.logoContainer}>
            <Image style={[styles.logo, styles.companyLogo]} src={customer.logo ? customer.logo : logo} />
              <View style={styles.tableContainer}>
                <View style={styles.tableCol}>
                  <View style={ styles.backGnd}>
                  <Text style={[styles.boldText, styles.pdng]}>
                    Faktura nr / Invoice No.: {product.series.toUpperCase()}
                  </Text>
                  </View>
                  <View style={[styles.row, styles.pdng, styles.tbborder]}>
                      <View style={styles.column}>
                          <Text style={[styles.smallText, styles.orangeText,{ textAlign: 'left' }]}>
                          Data wystawienia {"\n"} Invoice Date : {product.dateOfIssue}
                          </Text>:
                      </View>
                      <View style={styles.column}>
                      <Text style={[styles.smallText, styles.orangeText,{ textAlign: 'left' }]}>
                      Data sprzedaży {"\n"}Sale Date : {product.saleDate}
                          </Text>:
                    </View>

                  </View>
                  <View style={[styles.row, styles.pdng, styles.tbborder]}>
                  <View style={styles.column}>
                      <Text style={[styles.smallText, styles.orangeText,{ textAlign: 'left' }]}>
                      Termin płatności {"\n"}Payment Due By : {product.paymentDeadline}
                      </Text>:
                  </View>
                  <View style={styles.column}>
                      <Text style={[styles.smallText, styles.orangeText,{ textAlign: 'left' }]}>
                      Metoda płatności {"\n"}Payment Method: : {product.paymentMethod}
                      </Text>:
                  </View>
                  </View>
          </View>
        </View>
      </View>
    </View>


    <View style={styles.row1}>
      <View style={styles.column}>
      <Text style={styles.dpl}>
         <Text style={styles.dpl1}>Sprzedawca </Text>
         <Text style={styles.dpl2}>(Seller)</Text>
      </Text>
          <Text style={[styles.boldText, styles.dpl3]}>{customer.shortName}</Text>
          <Text style={styles.dpl3}>{customer.fullName}</Text>
          <Text style={styles.dpl3}>{customer.street}</Text>
          <Text style={styles.dpl3}>
            {customer.code}
            {customer.city}
          </Text>
          <Text style={styles.dpl3}>{customer.taxId}</Text>
          <Text style={styles.dpl3}>{customer.bankName}</Text>
          <Text style={styles.dpl3}>{customer.bankAccountNo}</Text>
       </View>
       <View>
       </View>
       <View style={styles.column}>
       <Text style={styles.dpl}>
         <Text style={styles.dpl1}>Nabywca </Text>
         <Text style={styles.dpl2}>(Bill to)</Text>
      </Text>
          <Text style={[styles.boldText, styles.dpl3]}>{company.shortName}</Text>
          <Text style={styles.dpl3}>{company.fullName}</Text>
          <Text style={styles.dpl3}>{company.street}</Text>
          <Text style={styles.dpl3}>
            {company.code}
            {company.city}
          </Text>
          <Text style={styles.dpl3}>{customer.taxId}</Text>
       </View>
    </View>

     <View ><Text style={[styles.emptyDiv, styles.bold]}>{product.series.toUpperCase()}</Text></View>
      <View style={styles.tableContainerOne_pro}>
        <View style={[styles.tableRow_pro, styles.row_pro]}>
          <Text style={[styles.tableHeader_pro, styles.column_pro, styles.box_pro_main]}>Lp{"\n"}no</Text>
          <Text style={[styles.tableHeader_pro, styles.column_pro,styles.box_pro_1_main]}>Nazwa{"\n"}Description</Text>
          <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro_main]}>PKWiU </Text>
          <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro_main]}>Jedn{"\n"}Unit</Text>
          <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro_main]}>Ilość{"\n"}Qty</Text>
          <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro_main]}>Cena netto{"\n"}Unit price</Text>
          <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro_dis_main]}>Rabata{"\n"}Discount</Text>
          <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro_main]}>Stawka{"\n"}Tax Rate</Text>
          <Text style={[styles.tableHeader_pro, styles.column_pro,styles.box_pro_2]}>Wartość netto{"\n"}Total Excl. Tax</Text>
          <Text style={[styles.tableHeader_pro, styles.column_pro,styles.box_pro_2]}>Wartość brutto{"\n"}Total Incl. Tax</Text>
        </View>
        {product?.products?.map((itm, index) => (
          <View style={[styles.tableRow_pro, styles.row_pro]} key={index}>
            <Text style={[styles.tableHeader_pro, styles.column_pro, styles.box_pro]}>{index + 1}</Text>
            <Text style={[styles.tableHeader_pro, styles.column_pro,styles.box_pro_1]}>{itm.name}</Text>
            <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro]}>{itm.pkwiu}</Text>
            <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro]}>{itm.unit}</Text>
            <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro]}>{itm.qty}</Text>
            <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro]}>{itm.netPrice}</Text>
            <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro]}>{itm.discount}%</Text>
            <Text style={[styles.tableHeader_pro, styles.column, styles.box_pro]}>{itm.rate}%</Text>
            <Text style={[styles.tableHeader_pro, styles.column_pro,styles.box_pro_2]}>{itm.worthNet}</Text>
            <Text style={[styles.tableHeader_pro, styles.column_pro,styles.box_pro_2]}>{itm.worthGross}</Text>
          </View>
        ))}
      </View>

      <View style={styles.divisionContainer}>
        <View style={styles.tableContainerOne_pro}>
          <View style={[styles.tableRow_total, styles.row_pro]}>
            <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]} >Stawka VAT{"\n"}Tax Rate</Text>
            <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]}>Wartość netto{"\n"}Total Excl. Tax</Text>
            <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]}>Kwota VAT{"\n"}Tax</Text>
            <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]}>Wartość brutto{"\n"}Total Incl. Tax</Text>
          </View>
          {product?.products?.reduce((acc, itm) => {
            const existingItem = acc.find((item) => item.rate === itm.rate);
            if (existingItem) {
              existingItem.placeA += parseFloat(itm.worthNet);
              existingItem.placeB += parseFloat(itm.worthGross - itm.worthNet);
              existingItem.placeC += parseFloat(itm.worthGross);
            } else {
              acc.push({
                rate: itm.rate,
                placeA: parseFloat(itm.worthNet),
                placeB: parseFloat(itm.worthGross - itm.worthNet),
                placeC: parseFloat(itm.worthGross),
              });
            }
            return acc;
          }, []).map((item, index, arr) => (
            <React.Fragment key={index}>
              <View style={[styles.tableRow_total, styles.row_pro]}>
                <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]}>{item.rate}%</Text>
                <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]}>{item.placeA.toFixed(2)} Zł</Text>
                <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]}>{item.placeB.toFixed(2)} Zł</Text>
                <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]}>{item.placeC.toFixed(2)} Zł</Text>
              </View>
              {index === arr.length - 1 && (
                <View style={[styles.tableRow_total, styles.row_pro]}>
                  <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]}>{props.t("Total")}</Text>
                  <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]}>
                    {arr.reduce((total, item) => total + item.placeA, 0).toFixed(2)} Zł
                  </Text>
                  <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]}>
                    {arr.reduce((total, item) => total + item.placeB, 0).toFixed(2)} Zł
                  </Text>
                  <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_total]}>
                    {arr.reduce((total, item) => total + item.placeC, 0).toFixed(2)} Zł
                  </Text>
                </View>
              )}
            </React.Fragment>
          ))}
        </View>

        <View style={styles.divisionContainer}>
          <View style={[styles.tableRow_final_1, styles.row_pro_final]}>
            <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_final]}>Zapłacono / Already Paid</Text>
            <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_final]}>
              {product.paid === "Yes" ? `${totalC} Zł` : "0,00 Zł"}
            </Text>
          </View>
          <View style={[styles.tableRow_final_2, styles.row_pro_final]}>
            <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_final]}>Do zapłaty / Total Due</Text>
            <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_final]}>
              {product.paid === "No"
                ? product.advance !== "0"
                ? `${totalC - product.advance} Zł`
                : `${totalC} Zł`
                : "0,00 Zł"}
            </Text>
          </View>
          <View style={[styles.tableRow_final_3, styles.row_pro_final]}>
            <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_final]}>Razem / Total</Text>
            <Text style={[styles.tableHeader_pro, styles.column_total, styles.box_pro_final]}>{totalC} Zł</Text>
          </View>
        </View>
      </View>

      <View style={styles.textContainerTwo}>
        <hr></hr>
        <Text style={[styles.row_pro_enpl]}>Słownie / In words</Text>
        <Text style={[styles.row_pro_enpl]}>{wordsInEnglish}</Text>
        <Text style={[styles.row_pro_enpl]}> {wordsInPolish}</Text>
      </View>

      <View style={styles.spaceContainer} />
        <View style={styles.calcu_footer} >
          <View style={[styles.divisionContainer1, styles.divisionContainerOne]}>
            <View style={[styles.textContainerThree]}>
              <Text style={[styles.box_pro_f1, styles.row_pro_f1, styles.bold_f1]}>
                {company.fullName.toUpperCase()}
              </Text>
              <Text style={[styles.box_pro_f1, styles.row_pro_f1]}>Imię i nazwisko osoby uprawnionej</Text>
              <Text style={[styles.box_pro_f1, styles.row_pro_f1,]}>do wystawiania faktury</Text>
              <Text style={[styles.box_pro_f1, styles.row_pro_f1,]}>seller's signature</Text>
            </View>
            <View style={[styles.textContainerThree, styles.textContainer3]}>
              <Text style={[styles.box_pro_f1, styles.row_pro_f1, styles.bold_f1]}>
                {customer.fullName.toUpperCase()}
              </Text>
              <Text style={[styles.box_pro_f1, styles.row_pro_f1]}>Imię i nazwisko osoby uprawnionej</Text>
              <Text style={[styles.box_pro_f1, styles.row_pro_f1]}>do odbioru faktury</Text>
              <Text style={[styles.box_pro_f1, styles.row_pro_f1,]}>buyer's signature</Text>
            </View>
          </View>
          <View>
          <Text style={[styles.box_pro_f1, styles.row_pro_f1, styles.textContainer_footer]}>
            www.freeinvoice.pl | generate invoice for free in small business
          </Text>
          </View>
        </View>
    </View>
  </Page>
    </Document>

  );
};

const styles = {
  outerContainer: {
    width: "812px",
    padding: "30px",
  },
  pdng:{
    padding:'5px'
  },
  backGnd:{
    backgroundColor: "rgba(213, 178, 112, 0.45)",
  },
  btnDownload: {
    height: "50px",
    width: "30%",
    borderRadius: "10px",
    borderColor: "rgba(0, 0, 0, 0)",
    fontSize: "large",
    fontWeight: "500",
    backgroundColor: "rgb(230, 253, 196)",
    boxShadow: "1px 1px 10px rgba(226, 95, 95, 0.5)",
    margin: "20px",
    transition: "background-color 0.3s ease-out",
  },
  btnDownloadHover: {
    backgroundColor: "#BCD700",
    boxShadow: "5px 5px 10px rgba(226, 95, 95, 0.5)",
  },
  dpl:{
    flexDirection: 'row',
  },
  dpl1:{
    fontSize:'12px',
    fontWeight:'bolder',
  },
  dpl2:{
    fontSize:'8px',
    fontStyle: 'italic',
  },
  dpl3:{
    fontSize:'10px',
    color:'black',
    fontFamily:'Roboto',
    marginTop:'2px',
  },
  divisionContainer1: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: 'row',
  },
  divisionContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  companyLogo: {
    width: "30%",
    padding: "20px",
  },
  tableOuterContainer: {
    width: "45%",
    marginTop: "20px",
  },
  smallText:{
    fontSize:'8px',
    fontFamily: 'Roboto',
  },
  tbborder:{
    borderTop:'1px solid black'
  },
  p: {
    margin: "0",
    padding: "2px",
  },
  tableContainer: {
    border: "1px solid black",
    position: 'absolute',
    left: '240px',
    width: '40%',
    top:'20px',
    textAlign:'center',
    fontSize:'12px',
  },
  tableColTypeOne: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
    textAlign: "center",
    backgroundImage: "linear-gradient(to bottom, white, rgb(197, 197, 197))",
  },
  tableColTypeTwo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
  },
  tableColTypeThree: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px",
  },
  divisionContainerOne: {
    display: "flex",
    marginTop: "30px",
  },
  textContainerOne: {
    position: 'absolute',
    left: '240px',
    width: '50%',
    top:'120px',
  },
  textContainer: {
    flex: "2",
  },
  bold: {
    fontWeight: "bold",
    background:"red",

  },
  bold1: {
    fontWeight: "500",
  },

  gradientContainer: {
    width: '70%',
    height: 40,
  },
 emptyDiv: {
  width:'72%',
  backgroundImage: 'red !important', // Adjust the color values as needed
  borderBottom: '1px solid #000', // Border only at the bottom
  justifyContent: 'center !important',
  alignItems: 'center !important',
  textAlign:'center',
  height: '30px', // Adjust the height as needed
  marginBottom: '10px', // Add some margin to separate from the next element
},
  tableContainerOne: {
    marginTop: "50px",
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:'center',
    fontSize:'8px',
    fontFamily: 'Roboto',
  },
  row1: {
    flexDirection: 'row',
    alignItems: '',
    justifyContent: 'center',
    textAlign:'left',
    width:'70%'
  },
  column: {
    flex: 1,
    marginLeft: 10, // Adjust the spacing between columns as needed
  },
//==================for product div=====================
   tableContainerOne_pro: {
    marginTop: "15px",
    padding:0,
  },
  tableHeader_pro:{
    margin:0,
  },
   column_pro: {
    marginLeft: 0,
  },
  row_pro: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:'center',
    fontSize:'8px',
    fontFamily: 'Roboto',
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
  },


  row_pro_enpl: {
    width:'71%',
    flexDirection: 'row',
    alignItems: 'right',
    justifyContent: 'right',
    textAlign:'right',
    fontSize:'8px',
    fontFamily: 'Roboto',
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
  },
tableRow_pro:{
    width:'72%',
    display: 'flex',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    border:"0.5px solid black",
  },
  box_pro:{
    width: 20, // Set a custom width
    display: 'flex',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    borderLeft: "0.5px solid black",
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
  },

  box_pro_main:{
    height: 42,
    display: 'flex',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    width: 20, // Set a custom width
    borderLeft: "0.5px solid black",
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
  },
  box_pro_1:{
    width: 200, // Set a custom width
    display: 'flex',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    borderLeft: "0.5px solid black",
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
  },
  box_pro_1_main:{
    height: 42,
    display: 'flex',
    width: 200, // Set a custom width
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    borderLeft: "0.5px solid black",
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
  },
  box_pro_2:{
    width: 40, // Set a custom width
    display: 'flex',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    borderLeft: "0.5px solid black",
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
  },
  box_pro_dis:{
    width: 100, // Set a custom width
    display: 'flex',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
    borderLeft: "0.5px solid black",
  },
  box_pro_dis_main:{
    height: 42,
    display: 'flex',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    width: 100, // Set a custom width
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
    borderLeft: "0.5px solid black",
  },
  //=====================================================
//================footer============================
row_pro_f1: {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign:'center',
  fontSize:'8px',
  fontFamily: 'Roboto',
  margin: 0, // Set margin to 0 to remove spacing between boxes
  padding: 0, // Set padding to 0 to remove spacing between boxes

},
box_pro_f1:{
  width: 200, // Set a custom width
  display: 'flex',
  textAlign:'center',
  justifyContent:'center',
  alignItems:'center',
  margin: 0, // Set margin to 0 to remove spacing between boxes
  padding: 0, // Set padding to 0 to remove spacing between boxes
},

bold_f1:{
  fontSize:'10px',
  fontWeight:'800',
  padding:'5'
},

textContainer3:{
  width:'50%',
  display: 'flex',
  position:'relative',
  top:0,
  textAlign:'center',
  justifyContent:'center',
  alignItems:'center',
  flexDirection: 'column',
},
textContainer_footer:{
  width:700,
  display: 'flex',
  position:'relative',
  bottom:-50,
  right:10,
  textAlign:'center',
  justifyContent:'center',
  alignItems:'center',
  flexDirection: 'column',
},
calcu_footer:{
  width:'72%',
  position:'relative',
  textAlign:'center',
  justifyContent:'center',
  alignItems:'center',
  padding:0,
  marginLeft:10,
  zindex:-1,
},

//========================================================
  //===============for total box====================

  tableRow_total:{
    width:'35%',
    display: 'flex',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    border:"0.5px solid black",
  },
   column_total: {
    flex: 1,
    marginLeft: 0,
  },
  box_pro_total:{
    width: 20, // Set a custom width
    display: 'flex',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    borderLeft: "0.5px solid black",
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
  },

  //====================================================

  //================final box ========================
  tableRow_final_1:{
    width:'35%',
    display: 'flex',
    position:'absolute',
    right:170,
    top:-40,
    textAlign:'left',
    justifyContent:'left',
    alignItems:'left',
    flexDirection: 'column',
  },
  tableRow_final_2:{
    width:'35%',
    display: 'flex',
    position:'absolute',
    right:170,
    top:-28,
    textAlign:'left',
    justifyContent:'left',
    alignItems:'left',
    flexDirection: 'column',
  },
  tableRow_final_3:{
    width:'35%',
    display: 'flex',
    position:'absolute',
    right:170,
    top:-16,
    textAlign:'left',
    justifyContent:'left',
    alignItems:'left',
    flexDirection: 'column',

  },
  row_pro_final: {
    flexDirection: 'row', // Align items horizontally within each row
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:'center',
    fontSize:'8px',
    fontFamily: 'Roboto',
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
  },

   column_final: {
    flex: 1,
    marginLeft: 0,
  },
  box_pro_final:{
    width: 20, // Set a custom width
    display: 'flex',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    margin: 0, // Set margin to 0 to remove spacing between boxes
    padding: 0, // Set padding to 0 to remove spacing between boxes
  },

  //==================================================
  table: {
    width: "100%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.5)",
    borderRight: "1px solid rgba(0, 0, 0, 0.5)",
    borderTop: "1px solid rgba(0, 0, 0, 0.5)",
    borderCollapse: "collapse",
    padding: "5px",
    fontWeight: "500",
    imageRendering: "crisp-edges",
    shapeRendering: "crispEdges",
  },
  tableRow:{
    width:'60%',
    textAlign:'center',
    justifyContent:'center',
    alignItems:'center',
    border:"1px solid black",
  },
  box:{
    width: '10%', // Set a custom width
    border: "1px solid black",
  },
  th: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
    borderCollapse: "collapse",
    padding: "5px",
    fontWeight: "500",
    justifyContent: "center",
    alignItems: "center",
  },
  td: {
    borderLeft: "1px solid rgba(0, 0, 0, 0.5)",
    borderCollapse: "collapse",
    padding: "5px",
    fontWeight: "500",
    justifyContent: "center",
    alignItems: "center",
  },
  tdAlignEnd: {
    textAlign: "end",
  },
  tableLeftText: {
    textAlign: "start",
    width: "450px",
  },
  textCenter: {
    textAlign: "center",
  },
  tableNoOutline: {
    border: "none",
    width: "250px",
  },
  tableNoOutlineTd: {
    border: "none",
  },
  tableTdWidth: {
    width: "150px",
    border: "1px solid red",
  },
  tableNoOutlineTrFirstTd: {
    textAlign: "start",
  },
  textContainerTwo: {
    textAlign: "end",
  },
  spaceContainer: {
    height: "100px",
  },
  textContainerThree: {
    width: '50%', // Each section takes half of the row
    display: 'flex',
    textAlign: "center",
  },
};



const PdfDownload = (props) => {
  const { product, company, customer } = props;

  return (
    <PDFDownloadLink
      document={<MyDocument product={product} company={company} customer={customer} />}
      fileName="reciept.pdf"
    >
      {({ blob, url, loading, error }) => (loading ? "Loading..." : "Download PDF")}
    </PDFDownloadLink>
  );
};

export default Index;
