import React from 'react'
import styles from '../style'
import Button from './Button'
import { translate } from '../lang';

const CTA = ({language}) => {
  return (
    <section className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
      <div className='flex-1 flex flex-col'>
        <h2 className={styles.heading2}>{translate("Let's try our service now!",language)}</h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        {translate("Everything you need is easy invoicing tool and grow your business anywhere on the planet.", language)}
        </p>
      </div>
      <div className={`${styles.flexCenter} sm:ml-10 ml-0`}>
        <Button language={language}/>
      </div>
    </section>
  )
}

export default CTA
