import React from 'react'
import { useNavigate } from 'react-router-dom';
import { translate } from '../lang';

const Button = ({ styles, language }) => {
  const history = useNavigate();
  const redirect = path => {
    history(path);
  };
  return (
    <button type='button' onClick={() => redirect('/app')} className="py-4 px-6 bg-gradient-to-r from-green-400 to-blue-500 hover:from-yellow-500 hover:to-green-500 font-poppins font-medium text-[18px] text-primary outline-none rounded-[10px]">
      {translate("Get Started",language)}
    </button>
  )
}

export default Button
