import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, MenuItem, Select, TextField, CircularProgress } from '@mui/material';
import axios from 'axios';
import { Label } from '@mui/icons-material';

const Customer = (props) => {
    const { handleNextTab } = props;
    const [nip, setNip] = useState("");
    const [disabled, setDIsabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        props.handleCustomerChange({[e.target.name] : e.target.value});
    };
    // const handleNipApi =async (event) =>{
    //     setNip(event.target.value);
    //     if(event.target.value.length === 10){
    //         setDIsabled(true);
    //         setLoading(true);
    //         const data = await axios.get("http://localhost:5000/company/"+event.target.value);
    //         console.log(data);
    //         if(data?.data?.root?.dane[0]?.ErrorMessageEn){
    //             setDIsabled(false);
    //             window.alert(data?.data?.root?.dane[0]?.ErrorMessageEn[0]);
    //             return;
    //         }
    //         // handle data
    //         const obj = {};
    //         obj['fullName'] = data?.data?.root?.dane[0]?.Nazwa[0];
    //         obj['id'] = data?.data?.root?.dane[0]?.Nip[0];
    //         obj['city'] = data?.data?.root?.dane[0]?.Miejscowosc[0];
    //         obj['code'] = data?.data?.root?.dane[0]?.KodPocztowy[0];
    //         obj['street'] = data?.data?.root?.dane[0]?.Ulica[0] +","+ data?.data?.root?.dane[0]?.NrNieruchomosci[0] + " " + data?.data?.root?.dane[0]?.NrLokalu[0] ;
    //         props.handleCustomerChange(obj);
    //         setDIsabled(false);
    //         setLoading(false);
    //     };

    // };

    const handleNipApi = async (event) => {
        const newNip = event.target.value;
        setNip(newNip);

        if (newNip.length === 10) {
          setLoading(true);

          try {
            const data = await axios.get(`https://invoiceserver-e064d7230481.herokuapp.com/company/${newNip}`);

            if (data?.data?.root?.dane[0]?.ErrorMessageEn) {
              window.alert(data?.data?.root?.dane[0]?.ErrorMessageEn[0]);
              return;
            }

            const obj = {
              fullName: data?.data?.root?.dane[0]?.Nazwa[0],
              id: data?.data?.root?.dane[0]?.Nip[0],
              city: data?.data?.root?.dane[0]?.Miejscowosc[0],
              code: data?.data?.root?.dane[0]?.KodPocztowy[0],
              street: `${data?.data?.root?.dane[0]?.Ulica[0]}, ${data?.data?.root?.dane[0]?.NrNieruchomosci[0]} ${data?.data?.root?.dane[0]?.NrLokalu[0]}`,
            };
            props.handleCustomerChange(obj);
          } catch (error) {
            console.error("Error fetching data from NIP API:", error);
          } finally {
            setLoading(false);
          }
        } else {
          // If NIP is not entered, update the form data directly from setFormDataCustomer in App.js
          props.handleCustomerChange({ ...props.formDataCustomer.customer, taxId: newNip });
        }



  setNip(event.target.value);

  if (event.target.value.length === 10) {
    setLoading(true);

    try {
      const data = await axios.get("https://invoiceserver-e064d7230481.herokuapp.com/company" + event.target.value);

      if (data?.data?.root?.dane[0]?.ErrorMessageEn) {
        window.alert(data?.data?.root?.dane[0]?.ErrorMessageEn[0]);
        return;
      }

      // handle data
      const obj = {};
      obj['fullName'] = data?.data?.root?.dane[0]?.Nazwa[0];
      obj['id'] = data?.data?.root?.dane[0]?.Nip[0];
      obj['city'] = data?.data?.root?.dane[0]?.Miejscowosc[0];
      obj['code'] = data?.data?.root?.dane[0]?.KodPocztowy[0];
      obj['street'] = data?.data?.root?.dane[0]?.Ulica[0] + "," + data?.data?.root?.dane[0]?.NrNieruchomosci[0] + " " + data?.data?.root?.dane[0]?.NrLokalu[0];
      props.handleCustomerChange(obj);
    } catch (error) {
      console.error("Error fetching data from NIP API:", error);
      // Handle error
    } finally {
      setLoading(false);
    }
  } else {
    // If NIP is not entered, update the form data directly from setFormDataCustomer in App.js
    props.handleCustomerChange({ ...props.formDataCustomer.customer });
  }
};



    const formDataCustomer = props.formDataCustomer;
    useEffect(() => {
        // Update the values when formDataCustomer changes
        setNip(formDataCustomer?.taxId || "");

      }, [formDataCustomer]);
    return(
        <Box sx={{ flexGrow: 1 }}>
            <Grid style={{marginBottom : 8}} container spacing={2}>
                <Grid item xs={6}>
                    <TextField sx={{width : "100%"}} disabled={disabled} value={nip}  onChange={disabled ? undefined : handleNipApi} name={props.t("nip")} label="Search NIP" variant="outlined" helperText="Please insert your NIP number to get information automatically | Prosimy o wpisanie numeru NIP, aby uzyskać informacje automatycznie"/>
                    {loading && <CircularProgress size={20} sx={{ marginLeft: 2 }} />} {/* Display loader when loading is true */}
                </Grid>
                <Grid item xs={3}>
                    <text style={{fontSize:'20px', display:'flex',alignItems:'center', height:'50px' }}> {props.t('or Login')}</text>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <TextField sx={{width : "100%"}} value={formDataCustomer?.fullName} onChange={handleChange} name="fullName" label={props.t("Full Name")} variant="outlined" />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField sx={{width : "100%"}} value={formDataCustomer?.shortName} onChange={handleChange} name="shortName" label={props.t("Short Name")} variant="outlined" />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField sx={{width : "100%"}} value={formDataCustomer?.email} onChange={handleChange} name="email" label={props.t("email")} variant="outlined" />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField sx={{width : "20%"}} value={formDataCustomer?.id} onChange={handleChange} name="taxId" label={props.t("taxId")} variant="outlined" />
                    <TextField sx={{width : "80%"}} value={formDataCustomer?.taxId} onChange={handleChange} name="id" label={props.t("id")} variant="outlined" />
                </Grid>
            </Grid>
            <Grid container mt={2} spacing={2}>
                <Grid item xs={12} md={3}>
                    <TextField sx={{width : "100%"}} value={formDataCustomer?.code} onChange={handleChange} name="code" label={props.t("Code")} variant="outlined" />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField sx={{width : "100%"}} value={formDataCustomer?.city} onChange={handleChange} name="city" label={props.t("City")} variant="outlined" />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField sx={{width : "100%"}} value={formDataCustomer?.street} onChange={handleChange} name="street" label={props.t("Street, House flat")} variant="outlined" />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{props.t("Country")}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formDataCustomer?.country}
                            onChange={handleChange}
                            name="country"
                            label={props.t("Country")}
                        >
                        <MenuItem value={"Poland"}>Poland / Polska</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField sx={{width : "100%"}} value={formDataCustomer?.bankName} onChange={handleChange} name="bankName" label={props.t("Bank Acount Name")} variant="outlined" />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField sx={{width : "100%"}} value={formDataCustomer?.bankAccountNo} onChange={handleChange} name="bankAccountNo" label={props.t("Bank Account Number")} variant="outlined" />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField sx={{width : "100%"}} value={formDataCustomer?.logo} onChange={handleChange} name="logo" label={props.t("Enter logo url")} variant="outlined" helperText="Please give any open link of your logo from dropbox, google drive or any public link | Podaj dowolny otwarty link do swojego logo z Dropbox, Dysku Google lub dowolnego linku publicznego"/>
                </Grid>
            </Grid>
            <Grid style={{ display:'flex', justifyContent:'right' }} item xs={6}>
            <Button style={{ margin: 10, Padding: 20,width: '150px', height: '40px'}} variant="outlined" onClick={handleNextTab}>Next</Button>
            </Grid>
        </Box>
    );

};

export default Customer;