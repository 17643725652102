import React from 'react'
import './PrivacyPolicy.css'
import Navbar from './Navbar'
import Footer from './Footer'

const Cookies = () => {
  return (
   <>
   <div style={{background:'black'}}>
    <Navbar/>
   <div className='container privacy-policy-container' style={{ color: 'white' }}>
  <h1>Informacje Ogólne</h1>

  <p>
    Niniejsza Polityka dotyczy plików "cookies" i odnosi się do witryn obsługiwanych
    przez Scopewit Sp. z o.o., zwanej dalej Stroną internetową.
  </p>

  <p>
    Strona internetowa nie zbiera automatycznie żadnych informacji, z wyjątkiem
    informacji zawartych w plikach cookies i podobnych narzędziach opisanych w tej polityce.
  </p>

  <h2>Czym są pliki cookies?</h2>

  <p>
    Pliki cookies (tzw. ciasteczka) to niewielkie pliki tekstowe wysyłane przez
    odwiedzaną stronę internetową do urządzenia użytkownika. Wykorzystujemy pliki
    cookies, aby ułatwić użytkownikom korzystanie z witryny oraz tworzyć statystyki
    korzystania z niej. Pliki cookies zawierają zazwyczaj nazwę witryny, z której
    pochodzą, czas przechowywania na urządzeniu końcowym oraz unikalny numer.
  </p>

  <p>
    Podmiotem umieszczającym pliki cookies na urządzeniu końcowym Użytkownika (użytkownika
    internetowego) i uzyskującym do nich dostęp jest: Scopewit Sp. z o.o.,
    z siedzibą we Wrocławiu, ul. Rynek 47, 50-101 Wrocław; wpisana do
    Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Wrocławia-Fabrycznej
    we Wrocławiu, VI Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS number - 0000770018; NIP: 8982248420, REGON: 382515870.
  </p>

  <h2>Do czego używamy plików cookies?</h2>

  <p>
    Pliki cookies są używane w celu:
  </p>

  <ul>
    <li>dostosowania zawartości stron Witryny do preferencji Użytkownika i optymalizacji korzystania z witryn;</li>
    <li>tworzenia anonimowych statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Witryny korzystają ze stron, co pozwala poprawić ich strukturę i zawartość.</li>
  </ul>

  <p>
    Dodatkowo strona internetowa zbiera anonimowe dane o odwiedzanych stronach, takie jak:
  </p>

  <ul>
    <li>liczba unikalnych wejść/odwiedzin,</li>
    <li>kraj,</li>
    <li>przeglądarka,</li>
    <li>czas wizyty itp.,</li>
    <li>strona, z której użytkownik wchodzi na witrynę (źródło).</li>
  </ul>

  <p>
    Dane są zbierane przy użyciu rozwiązania dostarczanego przez zewnętrznego dostawcę.
    Obecnie korzystamy z Google Analytics.
  </p>

  <p>
    Dane osobowe dobrowolnie podane przez Użytkownika w formularzach kontaktowych
    i zebrane za ich pośrednictwem są używane wyłącznie w celach kontaktowych.
    Korzystanie z formularza kontaktowego jest równoznaczne z wyrażeniem zgody na
    przetwarzanie przez Administratora danych osobowych Użytkownika podanych w formularzu.
  </p>

  <h2>Typy plików cookies</h2>

  <p>
    Na Witrynie używane są następujące rodzaje plików cookies:
  </p>

  <ul>
    <li>"niezbędne" pliki cookies umożliwiające korzystanie z usług dostępnych na Witrynie, np. pliki cookies uwierzytelniające używane do usług wymagających uwierzytelniania na Witrynie;</li>
    <li>pliki cookies służące zapewnieniu bezpieczeństwa, np. wykrywające nadużycia w zakresie uwierzytelniania na Witrynie;</li>
    <li>"wydajnościowe" pliki cookies umożliwiające zbieranie informacji o tym, w jaki sposób są używane strony Witryny;</li>
    <li>"funkcjonalne" pliki cookies umożliwiające "zapamiętywanie" ustawień wybranych przez Użytkownika i personalizowanie interfejsu Użytkownika, np. pod względem wybranego języka czy regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu Witryny itp.;</li>
    <li>pliki cookies związane z generowaniem statystyk i analizami oraz narzędzia takie jak:</li>
    <li>Local storage – pliki podobne do cookies, umożliwiające zbieranie anonimowych informacji o tym, w jaki sposób są używane strony Witryny, np. konfiguracja paska bocznego czy elementów w tabelach;</li>
    <li>Google Tag Manager z Google Analytics - monitorowanie ogólnego ruchu na naszych witrynach; zbieranie anonimowych, zagregowanych statystyk, które pozwalają zrozumieć, w jaki sposób użytkownicy korzystają z naszej witryny i umożliwiają ciągłe doskonalenie naszych produktów; określanie liczby anonimowych użytkowników naszych witryn. Potrzebne do analizy korzystania z witryn;</li>
    <li>Google Plus i Facebook-Widget – integracja ze stroną społecznościową.</li>
  </ul>

  <h2>Gdzie są przechowywane pliki cookies?</h2>

  <p>
    Najczęściej przeglądarki internetowe domyślnie zezwalają na przechowywanie plików cookies na
    komputerze użytkownika. Użytkownicy internetu mogą jednak samodzielnie zarządzać plikami
    cookies, w tym blokować je w dowolnym momencie. Wystarczy wybrać opcję odrzucenia plików
    cookies w przeglądarce. Należy jednak pamiętać, że wybór tej opcji może skutkować
    nieprawidłowym funkcjonowaniem niektórych funkcji witryny.
  </p>

  <p>
    Każdy użytkownik może spersonalizować ustawienia plików cookies w swojej przeglądarce.
    Niezmiana tych ustawień oznacza akceptację używanych plików cookies.
  </p>

  <p>
    Należy pamiętać, że zmiana ustawień tych plików przez ograniczenie ich używania może wpłynąć
    na niektóre funkcje dostępne na witrynie.
  </p>

  <p>
    Zobacz więcej: <a href="https://#/-informacje-o-cookies">https://#/-informacje-o-cookies</a>
  </p>
</div>;
<Footer/>
</div>
   </>
  )
}

export default Cookies