import './App.css';
import React, { useState, useEffect } from 'react';
import PdfWork from './components/reciept'
import Company from './components/Company';
import Product from './components/Product';
import {translate} from "./lang";
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import Customer from './components/Customer';
import  NumberToWordsComponent from'./components/wordToNumber'
import { Language } from '@mui/icons-material';
import LoginForm from './LoginForm';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import styles from './style'
import NavebarApp from './components/NavebarApp.jsx';


function App() {
  const [active, setActive] = useState("customer");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [new_customer, setNewCustomer] = useState(null);
  const [formDataCustomer, setFormDataCustomer] = useState({
    customer : {
      logo: "",
      fullName: "",
      shortName: "",
      taxId: "",
      id: "",
      email: "",
      code: "",
      city: "",
      street: "",
      country: "",
      sameMailing: true,
      mailingCode: "",
      mailingCity: "",
      mailingStreet: "",
      mailingCountry: "",
      bankName: "",
      bankAccountNo: ""
    },

  })
  const [formData, setFormData] = useState({
    company : {
      logo: "",
      fullName: "",
      shortName: "",
      taxId: "",
      id: "",
      email: "",
      code: "",
      city: "",
      street: "",
      country: "",
      sameMailing: true,
      mailingCode: "",
      mailingCity: "",
      mailingStreet: "",
      mailingCountry: "",
      bankName: "",
      bankAccountNo: ""
    },

    basic: {
      dateOfIssue: "",
      saleDate: "",
      paymentDeadline: "",
      series: "",
      advance: "",
      paymentMethod: "",
      Paid: "",
      invoiceDate: "",
      description: "",
      sum:0,
      products: [{
        sn: 1,
        name: "",
        pkwiu: "",
        unit: "",
        qty: 1,
        netPrice: 0,
        discount: 0,
        rate: 0,
        worthNet: "",
        worthGross: "",
        gtu: "",
      }]
    }


  });
  const [language, setLanguage] = useState("en");
  const handleCompanyChange = (obj) => {
    setFormData(ev => ({...ev, company : {...ev.company, ...obj}}));
  }

  const handleCustomerChange = (obj) => {
    setFormDataCustomer(ev => ({...ev, customer : {...ev.customer, ...obj}}));
  }

  const handleProductChange = (obj) => {
    setFormData(ev => ({...ev, basic : {...ev.basic, ...obj}}));
  }
  const handleLang = (event) => {
    const selectedLanguage = event.target.checked ? 'pl' : 'en';
    window.localStorage.setItem("lang", event.target.checked ? "pl" : "en");
    setLanguage(event.target.checked ? "pl" : "en");

  }

  const handleNextTab = () => {
    if (active === "customer") {
      setActive("detail");
    }
  };
  const handleNextTab_1 = () => {
    if (active === "product") {
      setActive("print");
    }
  };
  const handleNextTab_2 = () => {
    if (active === "detail") {
      setActive("product");
    }
  };


  const handleLoginSuccess = (userData) => {
    console.log("Received user data:", userData);

    if (userData.company) {
      const new_customer = {
        logo: userData.company.logoURL,
        fullName: userData.company.fullName,
        shortName: userData.company.shortName,
        taxId: userData.company.taxId,
        id: userData.company.id,
        email: userData.company.email,
        code: userData.company.code,
        city: userData.company.city,
        street: userData.company.street,
        country: userData.company.country,
        sameMailing: userData.company.sameMailing,
        mailingCode: userData.company.mailingCode,
        mailingCity: userData.company.mailingCity,
        mailingStreet: userData.company.mailingStreet,
        mailingCountry: userData.company.mailingCountry,
        bankName: userData.company.bankName,
        bankAccountNo: userData.company.bankAccountNo,
      };

      // Update the state using the new constant
      setFormDataCustomer((prevData) => ({
        ...prevData,
        customer: new_customer,
        // ... (other fields)
      }));
     // setIsLoggedIn(true);
     setActive("customer");
    }
  };

  const clearCustomerData = () => {
    setFormDataCustomer({
      customer: {
        logo: "",
        fullName: "",
        shortName: "",
        taxId: "",
        id: "",
        email: "",
        code: "",
        city: "",
        street: "",
        country: "",
        sameMailing: true,
        mailingCode: "",
        mailingCity: "",
        mailingStreet: "",
        mailingCountry: "",
        bankName: "",
        bankAccountNo: ""
        // ... (other fields)
      },
    });
  };


  return (
    <div className="App">
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
        <NavebarApp/>
        </div>
      </div>
      <div className="container">
        <div className="h3_recipt   shadow-2xl bg-gradient-to-r from-green-400 to-blue-500 ">
        <h3  >{translate("Invoice Details", language)}</h3>
        </div>
        <FormGroup style={{float: "right"}}>
          <FormControlLabel control={<Switch inputProps={{ 'aria-label': 'controlled' }} onChange={(event) => handleLang(event)} checked={language == "pl"} />} label="Polish" />
        </FormGroup>
        <ul className="nav nav-tabs">
          <li onClick={() => setActive("customer")} className={active == "customer" ? " bg-gradient-to-r from-pink-400 to-yellow-500 rounded" : ""}><a>{translate("Seller", language)}</a></li>
          <li onClick={() => setActive("detail")} className={active == "detail" ? "bg-gradient-to-r from-pink-400 to-yellow-500 rounded" : ""}><a>{translate("Customer Detail", language)}</a></li>
          <li onClick={() => setActive("product")} className={active == "product" ? "bg-gradient-to-r from-pink-400 to-yellow-500 rounded" : ""}><a>{translate("Product Information", language)}</a></li>
          <li onClick={() => setActive("print")} className={active == "print" ? "bg-gradient-to-r from-pink-400 to-yellow-500 rounded" : ""}><a>{translate("Print" , language)}</a></li>
          <li onClick={() => setActive("login")} className={active === "login" ? "bg-gradient-to-r from-pink-400 to-yellow-500 rounded" : ""}>
            <a>{translate("Login", language)}</a>
          </li>
        </ul>

      {active == "product" &&  <Product t={(txt) => translate(txt, language)} formData={formData?.basic} handleProductChange={handleProductChange} handleNextTab_1={handleNextTab_1}  />}
      {active == "detail" && <Company t={(txt) => translate(txt, language)} formData={formData?.company} handleCompanyChange={handleCompanyChange} handlePrint={() => setActive("print")} handleNextTab_2={handleNextTab_2} />}
      {active == "customer" &&  <Customer t={(txt) => translate(txt, language)} formDataCustomer={formDataCustomer?.customer} handleCustomerChange={handleCustomerChange} handlePrint={() => setActive("print")} handleNextTab={handleNextTab} />}
      {active == "print" && <PdfWork t={(txt) => translate(txt, language)} company={formData?.company}  product={formData?.basic} customer={formDataCustomer?.customer}/>}
      {active === "login" && !isLoggedIn && <LoginForm setIsLoggedIn={setIsLoggedIn} t={(txt) => translate(txt, language)} onLoginSuccess={handleLoginSuccess} clearCustomerData={clearCustomerData} />}


      </div>
);
    </div>
  );
}

export default App;
