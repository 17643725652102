import React from 'react'
import styles from '../style'
import { arrowUp } from '../assets'
import { useNavigate } from 'react-router-dom';
const GetStarted = () => {

  const history = useNavigate();
  const redirect = path => {
    history(path);
  };
  return (
    <div
      className={`${styles.flexCenter} w-[140px] h-[140px] rounded-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-yellow-500 hover:to-green-500 p-[2px] cursor-pointer`}
      onClick={() => redirect('/app')}// Attach the click handler
    >
    <div className={`${styles.flexCenter} flex-col bg-primary w-[100%] h-[100%] rounded-full`}>
        <div className={`${styles.flexStart} flex-row`}>
          <p className='font-poppins font-medium text-[18px] leading-[23px] mr-2'>
            <span className='text-gradient'>Get</span>
          </p>
          <img
            src={arrowUp}
            alt='arrow'
            className='w-[23px] h-[23px] object-contain'
          />
        </div>
        <p className='font-poppins font-medium text-[18px] leading-[23px]'>
          <span className='text-gradient'>Invoice</span>
        </p>
      </div>
    </div>
  )
}

export default GetStarted
