import { ContentCutOutlined } from "@mui/icons-material";
import React, { useState, useEffect } from "react";


const NumberToWordsEnglish = (number) =>{
  const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
  const teens = ["ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
  const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
  const places = ["", "thousand", "lakh", "crore"];

  const convertLessThanOneThousand = (num) => {
    let words = "";

    if (num >= 100) {
      words += units[Math.floor(num / 100)] + " hundred";
      num %= 100;
    }

    if (num >= 20) {
      words += " " + tens[Math.floor(num / 10)];
      num %= 10;
    }

    if (num > 0) {
      if (num < 10) {
        words += " " + units[num];
      } else {
        words += " " + teens[num - 10];
      }
    }

    return words.trim();
  };

  const convertNumberToWords = (num) => {
    if (num === 0) {
      return "zero";
    }

    let words = "";
    let placeIndex = 0;

    while (num > 0) {
      const part = num % 1000;
      if (part > 0) {
        const partWords = convertLessThanOneThousand(part);
        words = partWords + " " + places[placeIndex] + " " + words;
      }
      num = Math.floor(num / 1000);
      placeIndex++;
    }

    return words.trim();
  };

  let words = "";

  if (number === 0) {
    return "zero";
  }

  if (number < 0) {
    words += "minus ";
    number = Math.abs(number);
  }

  let decimalPart = "";
  if (Number.isInteger(number)) {
    words = convertNumberToWords(number);
  } else {
    const parts = number.toString().split(".");
    const wholeNumber = parseInt(parts[0]);
    const decimalNumber = parseInt(parts[1]);

    words = convertNumberToWords(wholeNumber);

    if (decimalNumber > 0) {
      decimalPart = " point ";
      for (let i = 0; i < Math.min(2, parts[1].length); i++) {
        decimalPart += units[parseInt(parts[1][i])] + " ";
      }
    }
  }

  return words + decimalPart;
};


const NumberToWordsPolish = (number) => {
  const units = ["zero", "jeden", "dwa", "trzy", "cztery", "pięć", "sześć", "siedem", "osiem", "dziewięć"];
  const teens = ["dziesięć", "jedenaście", "dwanaście", "trzynaście", "czternaście", "piętnaście", "szesnaście", "siedemnaście", "osiemnaście", "dziewiętnaście"];
  const tens = ["", "", "dwadzieścia", "trzydzieści", "czterdzieści", "pięćdziesiąt", "sześćdziesiąt", "siedemdziesiąt", "osiemdziesiąt", "dziewięćdziesiąt"];
  const hundreds = ["", "sto", "dwieście", "trzysta", "czterysta", "pięćset", "sześćset", "siedemset", "osiemset", "dziewięćset"];
  const groups = ["", "tysiąc", "milion", "miliard", "bilion", "biliard", "trylion", "tryliard", "kwadrylion", "kwadryliard"];

  const convertLessThanOneThousand = (num) => {
    let words = "";

    if (num >= 100) {
      words += hundreds[Math.floor(num / 100)] + " ";
      num %= 100;
    }

    if (num >= 20) {
      words += tens[Math.floor(num / 10)] + " ";
      num %= 10;
    }

    if (num > 0) {
      if (num < 10) {
        words += units[num] + " ";
      } else {
        words += teens[num - 10] + " ";
      }
    }

    return words.trim();
  };

  const convertNumberToWords = (num) => {
    if (num === 0) {
      return "zero";
    }

    let words = "";
    let groupIndex = 0;

    while (num > 0) {
      const part = num % 1000;
      if (part > 0) {
        const partWords = convertLessThanOneThousand(part);
        words = partWords + " " + groups[groupIndex] + " " + words;
      }
      num = Math.floor(num / 1000);
      groupIndex++;
    }

    return words.trim();
  };

  let words = "";

  if (number === 0) {
    return "zero";
  }

  if (number < 0) {
    words += "minus ";
    number = Math.abs(number);
  }

  let decimalPart = "";
  if (Number.isInteger(number)) {
    words = convertNumberToWords(number);
  } else {
    const parts = number.toString().split(".");
    const wholeNumber = parseInt(parts[0]);
    const decimalNumber = parseInt(parts[1]);

    words = convertNumberToWords(wholeNumber);

    if (decimalNumber > 0) {
      decimalPart = " przecinek ";

      // Convert decimal part to words
      for (let i = 0; i < Math.min(2, parts[1].length); i++) {
        decimalPart += units[parseInt(parts[1][i])] + " ";
      }
    }
  }

  return words + decimalPart;
};

const NumberToWordsComponent = ({totalC,language1}) => {
  const [language, setLanguage] = useState("en");
  const [language_pl, setLanguage_pl] = useState("pl");
  const [number, setNumber] = useState(0);

  useEffect(() => {
    if (totalC) {
   //   console.log("totalC prop updated:", totalC);
      setNumber(totalC);
    }
  }, [totalC]);

  useEffect(() => {
    if (language1 === "en" || language1 === "pl") {
      setLanguage(language1);
      if(language1 === "en"){
        setLanguage("en");
      }
      if(language1 === "pl"){
        setLanguage("pl");
      }
    }
  }, [language1]);


  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
  };

  const handleNumberChange = (e) => {
    setNumber(parseFloat(e.target.value));
  };



  let words;
  if (language === "en") {
    words = NumberToWordsEnglish(number);

  } else if (language === "pl") {
    words = NumberToWordsPolish(number);
    //console.log('pl ptinted')
  }
  return words;
  console.log("NumberToWordsComponent - final words:", words);
  // return (
  //   <div>
  //     <div>
  //       <p style={{display:"block"}}>Words: <strong>{ NumberToWordsEnglish(number)}</strong> </p>
  //       <p style={{display:"block"}}>Słownie: <strong>  { NumberToWordsPolish(number)} </strong> </p>
  //     </div>
  //   </div>
  // );
};

export default NumberToWordsComponent;
