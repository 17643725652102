import React from 'react'
import { feedback } from '../constants'
import styles from '../style'
import Feedback from './Feedback'
import { translate } from '../lang';

const Testimonials = ({language}) => {
  return (
    <section id='clients' className={`${styles.paddingY} ${styles.flexce} flex-col relative`}>
      <div className='absolute z-[0] w-[60%] h-[60%] -right-[50%] rounded-full blue__gradient bottom-40'/>
      <div className='w-full flex justify-between items-center md:flex-row flex-col sm:mb-16 mb-6 relative z-[1]'>
        <h2 className={styles.heading2}> {translate("What people are", language)} <br className='sm:block hidden'/>{translate("saying about us", language)}</h2>
        <div className='w-full md:mt-0 mt-6'>
          <p className={`${styles.paragraph} text-left max-w-[450px]`}>
          {translate("Everything you need easy invoicing tool and grow your business anywhere on the planet.", language)}
          </p>
        </div>
      </div>
      <div className='flex flex-wrap sm:justify-start justify-center w-full feedback-contrainer relative z-[1]'>
        {feedback.map((card) => (
          <Feedback key={card.id} {...card} language={language}/>
        ))}
      </div>
    </section>
  )
}

export default Testimonials
