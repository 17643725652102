import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const Rodo = () => {
  return (
    <>
    <div style={{background:'black'}}>
    <div className='container privacy-policy-container' style={{color:'white'}} >
    <Navbar/>
    <div className='container privacy-policy-container' style={{ color: 'white' }}>
  <h1>Bezpieczeństwo Danych Osobowych</h1>

  <p>
    System działa zgodnie z wymogami Ustawy o Ochronie Danych Osobowych oraz aktami wykonawczymi
    precyzującymi wymagania dotyczące systemów informatycznych, w których przetwarzane są dane osobowe.
  </p>

  <h2>Ochrona przetwarzania danych osobowych</h2>

  <p>
    Dbając o bezpieczeństwo, #.pl umożliwia:
  </p>

  <ul>
    <li>szyfrowaną transmisję danych,</li>
    <li>zabezpieczenie dostępu do systemu hasłem składającym się z co najmniej 8 znaków, które można zmieniać co 30 dni, a także zapewnia możliwość bezpiecznego logowania dwuetapowego,</li>
    <li>tworzenie kopii zapasowej danych przechowywanych na serwerze zlokalizowanym w Europejskim Obszarze Gospodarczym,</li>
    <li>formalne powierzenie przetwarzania danych osobowych na podstawie pisemnej umowy,</li>
    <li>kontrolę rejestrów dzięki automatycznemu rejestrowaniu informacji o tym, jakie dane zostały wprowadzone do systemu i przez kogo.</li>
  </ul>

  <p>
    W ramach "Raportów GDPR" użytkownik może usunąć dane z formularza logowania, a zapisane dane zostaną usunięte.
    Aby usunąć profil, prosimy o kontakt pod adresem e-mail: <a href="mailto:deletemyprofile@#.com">deletemyprofile@#.com</a>.
  </p>
</div>

<div className='container privacy-policy-container' style={{ color: 'white' }}>
  <h1>Security of personal data</h1>

  <p>
  The system operates in accordance with the requirements of the Personal Data Protection Act and implementing regulations specifying the requirements for IT systems in which personal data are processed.
  </p>

  <h2>Protection of personal data processing</h2>

  <p>
  Taking care of security, #.pl enables:
  </p>

  <ul>
    <li>encrypted data transmission,</li>
    <li>securing access to the system with a password of at least 8 characters, which can be changed every 30 days and also provides the possibility of secure two-step login,</li>
    <li>creating a backup copy of data stored on a server located in the European Economic Area,</li>
    <li>formally  entrusting the processing of personal data  on the basis of a written contract,</li>
    <li>control of records thanks to automatic recording of information about what data was entered into the system and by whom.</li>
  </ul>

  <p>Under "GDPR Reports", the user can delete data from the login form and the saved data will be deleted.
    To delete your profile, please contact us at the following e-mail address: <a href="mailto:deletemyprofile@#.com">deletemyprofile@#.com</a>.
  </p>
</div>

    </div>
    <Footer/>
    </div>
    </>
  )
}

export default Rodo