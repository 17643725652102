import React from 'react'
import { card } from '../assets'
import Button from './Button'
import styles, { layout } from '../style'
import { translate } from '../lang';

const CardDeal = ({language}) => {
  return (
    <section className={layout.section}>
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>{translate("Find a better Invoice tool", language)}  <br className='sm:block hidden'/>{translate("in few easy steps.", language)}</h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        {translate("You can quickly issue an invoice, easily calculate taxes and settle accounts with Accountant. Simple, convenient, without unnecessary complications.", language)}
        </p>
        <Button language={language} styles='mt-10'/>
      </div>
      <div className={layout.sectionImg}>
        <img
          src={card}
          alt='card'
          className='w-[100%] h-[100%]'
        />
      </div>
    </section>
  )
}

export default CardDeal
