import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Privacy from './components/Privacy'
import Cookies from './components/Cookies'
import Rodo from './components/Rodo'
import Button from './components/Button';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import Test from './Test';
import { BrowserRouter, Routes, Route } from "react-router-dom";// Import necessary components from react-router-dom
import Tc from './components/Tc';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
    <Route exact path="/" element={<Home/>}></Route>
    <Route exact path="/app" element={<App />}></Route>
    <Route exact path="/privacy" element={<Privacy/>}></Route>
    <Route exact path="/cookies" element={<Cookies/>}></Route>
    <Route exact path="/rodo" element={<Rodo/>}></Route>
    <Route exact path="/tc" element={<Tc/>}></Route>
    </Routes>
    </BrowserRouter>
   {/* <App/>*/}
  </React.StrictMode>
);


reportWebVitals();
