import React,{ useState, useEffect } from 'react'
import {Billing,Business,CardDeal,Clients,CTA,Footer,Hero,Stats,Testimonials} from './components'
import Navbar from '../src/components/Navbar'
import styles from './style'
import CookieConsent from 'react-cookie-consent';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';


const Home = () => {
  const [language, setLanguage] = useState("pl");
  const handleLang = (event) => {
    const selectedLanguage = event.target.checked ? 'pl' : 'en';
    window.localStorage.setItem("lang", event.target.checked ? "pl" : "en");
    setLanguage(event.target.checked ? "pl" : "en");


  }
  return (
    <div className='bg-primary w-full overflow-hidden'>
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`} style={{ display: 'flex', height:'50px'}}>
        <Navbar language={language} />
        <FormGroup className='bg-gradient-to-r from-green-400 to-blue-500 hover:from-yellow-500 hover:to-green-500 font-medium text-[18px] outline-none rounded-[10px] py-2' style={{float: "right", marginLeft:'10px',marginTop:'3px', paddingLeft:'5px', borderRadius:'10px'}}>
          <FormControlLabel control={<Switch inputProps={{ 'aria-label': 'controlled' }} onChange={(event) => handleLang(event)} checked={language == "pl"} />}  label="PL/EN" />
        </FormGroup>
        </div>
      </div>
      <div className={`bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Hero language={language}/>
        </div>
      </div>
      <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>

          <Stats language={language}/>
          <Business language={language}/>
          <Billing language={language}/>
          <CardDeal language={language}/>
          <Testimonials language={language}/>
          <Clients language={language}/>
          <CTA language={language}/>
          <Footer language={language}/>
        </div>
      </div>
      <div id="root"></div>

{/* Cookie consent popup */}
<CookieConsent
  location="bottom"
  buttonText="Akceptuję"
  cookieName="myAwesomeCookieName2"
  style={{ background: '#2B373B' }}
  buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
  expires={150}
>
  Używamy plików cookies na naszej stronie internetowej. Kontynuując
  korzystanie z naszej strony internetowej, bez zmiany ustawień prywatności
  przeglądarki, wyrażasz zgodę na przetwarzanie Twoich danych osobowych takich
  jak adres IP czy identyfikatory plików cookies w celach marketingowych, w
  tym wyświetlania reklam dopasowanych do Twoich zainteresowań i preferencji,
  a także celach analitycznych i statystycznych przez Scopewit
  Sp. z o.o. z siedzibą we Wrocławiu (Administrator), a także na zapisywanie i
  przechowywanie plików cookies na Twoim urządzeniu. Pamiętaj, że zawsze
  możesz zmienić ustawienia dotyczące plików cookies w swojej przeglądarce.
  Więcej informacji znajdziesz w naszej
</CookieConsent>
    </div>
  )
}

export default Home