import React from 'react'
import { features } from '../constants'
import styles, { layout } from '../style'
import Button from './Button'
import { translate } from '../lang.js';

const FeatureCard = ({ icon, title, content, description, index, language }) => (
  <div className={`flex flex-row p-6 rounded-[20px] ${index !== features.length - 1 ? 'mb-6' : 'mb-0'} feature-card`}>
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}>
      <img
        src={icon}
        alt='icon'
        className='w-[50%] h-[50%] object-contain'
      />
    </div>
    <div className='flex-1 flex flex-col ml-3'>
      <h4 className='font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1'>
      {translate(title, language)}
      </h4>
      <p className='font-poppins font-normal text-dimWhite text-[16px] leading-[24px]'>
      {translate(content, language)}
      </p>
    </div>
  </div>
)

const Business = ({language}) => {
  return (
    <section id='features' className={layout.section}>
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>{translate("You do the business,", language)}  <br className='sm:block hidden'/>{translate("we'll handle the Invoice.", language)} </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        {translate("With the right invoice tool,you can improve your financial life by easy generating invoice,easily generate invoice and save time.But without login generate samll invoices.", language)}
        </p>
        <Button language={language} styles='mt-10'/>
      </div>
      <div className={`${layout.sectionImg} flex-col`}>
        {features.map((feature, index) => (
          <FeatureCard key={feature.id} {...feature} index={index} language={language}/>
        ))}
      </div>
    </section>
  )
}

export default Business
