import {
  people01,
  people02,
  people03,
  facebook,
  instagram,
  linkedin,
  twitter,
  airbnb,
  binance,
  coinbase,
  dropbox,
  send,
  shield,
  star
} from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "features",
    title: "Features",
  },
  {
    id: "product",
    title: "Product",
  },
  {
    id: "clients",
    title: "Clients",
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Totally Free",
    content:
      "The best invoice tool offer some small business, restaurents and shops",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "100% Secured",
    content:
      "We take proactive steps make sure your information and invoices are secure.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Transfer Link",
    content:
      "A balance transfer via free invoice too can save your time a via direct transfer link.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Free invoice is only a tool for small business. It will take you wherever you wish, but it will not replace you as the driver.",
    name: "Herman Jensen",
    title: "Founder & Leader",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "Easy invoice makes your life easier. If you're lucky to have it, you're lucky.",
    name: "Steve Mark",
    title: "Founder & Leader",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "It is usually people in the money business, finance, and international trade that are really rich to get free invoice.",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: people03,
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "User Active",
    value: "3800+",
  },
  {
    id: "stats-2",
    title: "Trusted by small business",
    value: "230+",
  },
  {
    id: "stats-3",
    title: "Downloads",
    value: "$230M+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Home",
        link: "/#home",
      },
      {
        name: "Features",
        link: "/#features",
      },
      {
        name: "Products",
        link: "/#product",
      },
      {
        name: "Clients",
        link: "/#clients",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "GDPR compliant program",
        link: "/rodo",
      },
      {
        name: "Information about cookies",
        link: "/cookies",
      },
      {
        name: "Terms & Services",
        link: "/tc",
      },
      {
        name: "Privacy policy",
        link: "/privacy",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Become a Partner",
        link: "",
      },
    ],
  },
];

export const socialMedia = [

  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];